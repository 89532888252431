import React, {useContext, useEffect, useState} from 'react'
import {Outlet, useLoaderData, useRevalidator, useLocation, useNavigate} from "react-router-dom";

import Header from "./header"
import Footer from "./footer"

import ValidatePhoneScreen from "./protected_pages/startScreen/validatePhoneScreen";
import OptionScreenChoice from "./protected_pages/startScreen/OptionScreenChoice";

import {TwilioProvider} from "./other/Twilio";
import {isMobile, isAndroid, isIOS} from "react-device-detect";
import {AuthContext} from "./userAuth/AuthContext";
import Api from "./helper/api";
import BackgroundData from "./other/backgroundData";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const Layout = () => {
    const {state: ContextState, setNewNotifications, setNotifications, setMissionLinkings, setHelpLinkings, setSwitchDutyLinkings} = useContext(AuthContext)
    const [lastRoute, setLastRoute] = useState("")
    const [currentRoute, setCurrentRoute] = useState("")
    const query = useQuery();

    //handle registration step if not completed
    const {isLoggedIn, showVerifiedPhoneScreen, showOptionChoiceScreen, user} = useLoaderData()
    const revalidator = useRevalidator()

    //scroll to top on route change
    let location = useLocation();
    // let intervalNotifications =  null
    let backgroundSyncInterval =  null

    const api = new Api();

    if (isMobile && location.pathname.substring(1) === "download-app") {
        if (isAndroid) {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.esterlaw.app&hl=fr&pli=1'
        } else if (isIOS) {
            window.location.href = 'https://apps.apple.com/fr/app/ester-law/id6466040594'
        }
    }
    const navigate = useNavigate()

    useEffect( () => {
        let route = location.pathname.substring(1)

        if (!isLoggedIn && backgroundSyncInterval) {
            clearInterval(backgroundSyncInterval)
        }
        if (((isMobile && route.indexOf("profile/user") === -1) || (!isMobile && route.indexOf("profile") === -1)) && isLoggedIn && user && user.verified && !user.updatedAt) {
            if (isMobile) {
                return navigate("/profile/user")
            } else {
                return navigate("/profile")
            }
        // } else if (isLoggedIn && user && user.verified && user.job != 0 && user.updatedAt && !user.subscription && route.indexOf("abonnements") === -1) {
        // } else if (isLoggedIn && user && user.verified && user.updatedAt && !user.subscription && route.indexOf("abonnements") === -1) {
        //     return navigate("/abonnements")
        } else if (isLoggedIn && user && user.verified && user.updatedAt && !user.subscription && route.indexOf("missions") !== -1 && query.has('create')) {
            return navigate("/abonnements")
        } else if (route === "" && isLoggedIn) {
            return navigate("/dashboard")
        } else if (route === "" && !isLoggedIn) {
            return navigate("/connexion")
        }

        route = route === "" ? "index" : route

        console.log(isLoggedIn, 'isLoggedIn')
        console.log(user?.verified, 'verified')
        console.log(user?.updatedAt, 'updatedAt')
        console.log(user?.job, 'job')

        console.log(route, 'ROUTE')

        if (lastRoute !== "" && route !== lastRoute) {
            window.scrollTo(0, 0)
        }

        setLastRoute(route)

        let _route = route
        if (route.indexOf('/') !== -1) {
            _route = route.substring(0, route.indexOf('/'))
        }
        setCurrentRoute(_route)

        //refresh loader data
        revalidator.revalidate()

    },[location])

    // const loadNotifications = () => {
    //     Api.getUserNewNotifications().then(data => {
    //         if (data?.new === true) {
    //             // setNewNotifications(true)
    //         } else {
    //             // setNewNotifications(false)
    //         }
    //     })
    // }

    // useEffect(() => {
    //     if (isLoggedIn && !showVerifiedPhoneScreen && !showOptionChoiceScreen) {
    //         console.log("LAYOUT IS LOGIN CHANGED")
    //         backgroundSyncInterval = setInterval(() => {
    //             console.log("LOAD BACKGROUND INTERVAL")
    //             loadNotifications()
    //             // loadLinkings()
    //             // loadHelps()
    //             // loadMissions()
    //             // loadSwitchDuties()
    //         },  15000);
    //         loadNotifications()
    //         // return () => {
    //         //     clearInterval(backgroundSyncInterval)
    //         // }
    //     }
    // }, [isLoggedIn]);

    return (
            <div className={"main"}>
                {(!isLoggedIn || !ContextState.user.verified || (!ContextState.user.mission && !ContextState.user.skill)) && <div className={"sidebar-left"}>
                    <div className="logo"></div>
                    <p></p>
                </div>}

                <div className="page_wrapper" id={"page_" + currentRoute + "_wrapper"} data-profil={user?.profileType}>
                    <TwilioProvider isLoggedIn={isLoggedIn}>
                        <Header user={user} isLoggedIn={isLoggedIn && !showVerifiedPhoneScreen && !showOptionChoiceScreen} />
                        <div className="content_wrapper" id={"content_" + currentRoute + "_wrapper"}>
                            {isLoggedIn && showVerifiedPhoneScreen && <ValidatePhoneScreen/>}
                            {isLoggedIn && !showVerifiedPhoneScreen && showOptionChoiceScreen && <OptionScreenChoice user={user}/>}
                            {!showVerifiedPhoneScreen && !showOptionChoiceScreen && <Outlet context={{user : user}} />}
                        </div>
                        {/*{isLoggedIn && !showVerifiedPhoneScreen && !showOptionChoiceScreen && <BackgroundData />}*/}
                    </TwilioProvider>
                    {isLoggedIn && !showVerifiedPhoneScreen && !showOptionChoiceScreen && <Footer/>}
                </div>
            </div>
    );

}
export default Layout;
