import React, {useEffect, useState} from 'react';
import {useChat} from "../../other/Twilio";
import {Link, useNavigate, useOutletContext, useSearchParams} from "react-router-dom";
import {isMobile} from 'react-device-detect';
import Api from "../../helper/api";
import SingleMatch from "../match/SingleMatch";
import ConversationSingle from "./ConversationSingle";
import {GenericEmptyItem} from "../../other/GenericBlock";
import { formatDistance } from "date-fns";
import { fr } from "date-fns/locale";
import Linkify from 'react-linkify';
import userCircle from '../../../images/mobile/user-circle.png'
import {
    STEP_AGREEMENT,
    STEP_DONE,
    STEP_RECIPIENT_ACCEPTED, STEP_RECIPIENT_REFUSED,
    STEP_SENDER_ACCEPTED,
    STEP_SENDER_REFUSED
} from "../../other/Constant";

export const ConversationBlock = ({conversation, linking, showTitle, onClick}) => {

    const navigate = useNavigate()
    const {user} = useOutletContext();
    const {chatClient} = useChat()
    const [message, setMessage] = useState(null)
    const [lastMessage, setLastMessage] = useState(null)

    const getSubtitle = () => {
        if (linking?.mission) {
            return `${linking?.mission.reference} - Mission`
        } else if (linking?.help) {
            return 'Aide confraternelle'
        } else if (linking?.switchDuty) {
            return 'Échange de permanence'
        } else {
            return 'Mission'
        }
    }

    const handleNewMessage = (message) => {
        if (message.channel.sid === conversation?.sid) {
            setLastMessage(message);
            setMessage(message.state.body.replace(/\n+/g, ' ').substring(0, 150));
        }
    };

    useEffect(() => {
        if (chatClient === null) {
            return;
        }

        let message = conversation.messages[conversation.messages?.length - 1];

        if (message) {
            // console.log(message, 'LAST MESSAGE')
            // console.log(user.uid, 'LAST UID')
            setLastMessage(message)
            setMessage(message.state.body.replace(/\n+/g, ' ').substring(0, 150))
        }

        // Abonner à l'événement de réception de message
        chatClient.on('messageAdded', handleNewMessage);

        // Nettoyer les abonnements à l'événement lors du démontage du composant
        return () => { chatClient.off('messageAdded', handleNewMessage); };

    }, [chatClient]);

    return(
        <div className={"conversation_block_item"} key={conversation.sid} onClick={onClick}>
            <div className={"picture"}>
                <img src={linking?.user?.profilePicture ? linking.user?.profilePicture : userCircle} />
            </div>
            <div className={"content"}>
                {showTitle && <div className={"title"}>{conversation.friendlyName}</div>}
                {!showTitle && <div className={"title"}>{`${linking?.user?.firstname} ${linking?.user?.lastname}`}</div>}
                {showTitle && <div className={"subtitle"}>{getSubtitle()}</div>}
                {conversation.messages?.length > 0 && <div className={"last_message"}>
                    <div dangerouslySetInnerHTML={{__html: message}}></div>
                </div>}
            </div>
            {conversation.lastMessage?.dateCreated && <div className={"extra"}>
                {lastMessage && <div className={"time"}><span className={"author-info"}>{linking && lastMessage.author === user?.uid ? "Envoyé" : "Reçu"}</span> il y a {formatDistance(new Date(lastMessage.dateCreated), new Date(), { locale: fr })}</div>}
                {/*{conversation.unreadCnt > 0 && <span className={"dot"}>{conversation.unreadCnt}</span>}*/}
            </div>}
            {/*{!showTitle && <Link className={"conversation-link-see d-mobile-none"}>Voir</Link>}*/}
        </div>
    )
}

ConversationBlock.defaultProps = {
    conversation : null,
    showTitle : true
}

const Conversations = ({limit = 0}) => {
    const {conversations} = useChat()
    const navigate = useNavigate()
    const api = new Api();
    const [currentLinking, setCurrentLinking] = useState(null)
    const [currentConv, setCurrentConv] = useState(null)
    const [currentType, setCurrentType] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams();
    const [linkings, setLinkings] = useState([])
    const [filteredConversations, setFilteredConversations] = useState([])
    // const [convs, setConvs] = useState(conversations)

    useEffect(() => {
        let c = null
        if (searchParams.has("c") && searchParams.get("c") && !isMobile && filteredConversations.length > 0) {
            c = filteredConversations.find(obj => { return obj.sid == searchParams.get("c")})
        } else if (!limit && !isMobile && filteredConversations.length > 0 && !currentConv) {
            c = filteredConversations[0]
        }
        if (c) {
            setCurrentConv(c)
            const currentLinking = linkings?.filter(linking => {
                return linking.uid === c.sid;
            })
            if (currentLinking) {
                const linking = linkings.find(obj => { return obj?.uid === c.sid })
                let linkingType = 'linkings'
                if (linking.help) {
                    linkingType = 'help_linkings'
                } else if (linking.switchDuty) {
                    linkingType = 'switch_duty_linkings'
                }
                setCurrentType(linkingType)
            }
        }
    }, [filteredConversations])

    useEffect(() => {
        const steps = [STEP_RECIPIENT_ACCEPTED, STEP_RECIPIENT_REFUSED, STEP_SENDER_ACCEPTED, STEP_SENDER_REFUSED, STEP_AGREEMENT, STEP_DONE]
        const promiseMission = new Promise((resolve, reject) => {
            api.getMissionLinkings(null, steps).then(linkings => {
                resolve(linkings)
            })
        });
        const promiseHelp = new Promise((resolve, reject) => {
            api.getHelpLinkings(null, steps, null).then(linkings => {
                resolve(linkings)
            })
        });
        const promiseHelpMe = new Promise((resolve, reject) => {
            api.getHelpLinkings(null, steps, true).then(linkings => {
                resolve(linkings)
            })
        });
        const promiseSwitchDuty = new Promise((resolve, reject) => {
            api.getSwitchLinkings(null, steps, null).then(linkings => {
                resolve(linkings)
            })
        });
        const promiseSwitchDutyMe = new Promise((resolve, reject) => {
            api.getSwitchLinkings(null, steps, true).then(linkings => {
                resolve(linkings)
            })
        });

        Promise.all([promiseMission, promiseHelp, promiseHelpMe, promiseSwitchDuty, promiseSwitchDutyMe]).then((values) => {
            // console.log(values[0], 'MISSIONS')
            setLinkings([...values[0], ...values[1], ...values[2], ...values[3], ...values[4]])
        });

    }, []);

    // useEffect(() => {
    //     setConvs(limit > 0 ? conversations.slice(0, limit) : conversations)
    //
    // }, [conversations])

    useEffect(() => {
        // let convs = conversations
        console.log(conversations, 'CONVERSATIONS VALUES')
        console.log(linkings, 'LINKINGS VALUES')

        let convs = conversations.filter(conv => {
            return linkings?.filter(linking => {
                return linking.uid == conv.sid;
            }).length
        })
        setFilteredConversations(limit > 0 ? convs.slice(0, limit) : convs)
    }, [conversations, linkings])

    return (
        <div id={"page_conversation"}>
            <h2>Messagerie</h2>

            <div className={"conversation_wrapper"}>
                <div className={"side-right"}>
                    {!filteredConversations || filteredConversations.length === 0 && <>
                        <GenericEmptyItem title={"Aucune discussion pour le moment"} />
                    </>}
                    {filteredConversations.sort((a, b) => {
                        if (b.lastMessage && a.lastMessage){
                            return b.lastMessage.dateCreated.getTime() > a.lastMessage.dateCreated.getTime() ? 1 : -1
                        }
                        return b.dateUpdated?.getTime() > a.dateUpdated?.getTime() ? 1 : -1
                    }).map((conversation, key) => {
                        const linking = linkings.find(obj => { return obj?.uid === conversation.sid })
                        if (linking) {
                            let linkingType = 'linkings'
                            if (linking.help) {
                                linkingType = 'help_linkings'
                            } else if (linking.switchDuty) {
                                linkingType = 'switch_duty_linkings'
                            }
                            return <ConversationBlock key={key} conversation={conversation} linking={linking} onClick={() => {
                                if (isMobile) {
                                    navigate(`/messagerie/${conversation.sid}/${linkingType}`)
                                } else if (limit) {
                                    navigate(`/messagerie?c=${conversation.sid}&t=${linkingType}`)
                                } else {
                                    setCurrentConv(conversation)
                                    setCurrentType(linkingType)
                                }
                            }} />
                        }
                    })}
                </div>
                {!isMobile && limit == 0 && <div className={"side-left border-side-left"}>
                    {currentConv !== null && currentType !== null && <ConversationSingle conv={currentConv} type={currentType} />}
                </div>}
            </div>
        </div>
    )

}

export default Conversations