import React, {useContext, useEffect, useState} from "react";
import {Link, useLoaderData, useLocation, useOutletContext} from "react-router-dom";
import Api from "../../helper/api";
import * as Constant from "../../other/Constant";
import {AuthContext} from "../../userAuth/AuthContext";
import ProfileHeader from "./ProfileHeader";
import {isMobile} from "react-device-detect";
import MissionBlock from "../mission/_MissionBlock";
import {GenericEmptyItem} from "../../other/GenericBlock";
import ProfileMission from "./ProfileMission";
import Sponsorship from "../sponsorship/Sponsorship";
import Setting from "../setting/Setting";
import SubscriptionDashboard from "../subscription/SubscriptionDashboard";
import ProfileDashboard from "./ProfileDashboard";
import {useSetState} from "react-use";
import Subscriptions from "../subscription/Subscriptions";
import SlidingPanel from "../_sliding_panel";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const ProfileMenu = () => {

    const {user} = useOutletContext();
    const {updateUserInGlobalContext, logout} = useContext(AuthContext)
    const [filterValue, setFilterValue] = useState(!user.updatedAt ? 1 : 0)
    const {missions} = useLoaderData()
    const [showEnableProfile, setShowEnableProfile] = useState(false)
    const query = useQuery();

    const handleUserChange = (type) => {
        const api = new Api()

        api.UpdateUser(user.id, {
            profileType : type
        }).then((user) => updateUserInGlobalContext(user))
    }

    const enableProfile = () => {
        const api = new Api()

        let data = {}
        if (user.skill === false) {
            data.skill = true
            data.profileType = 1
        }
        if (user.mission === false) {
            data.mission = true
            data.profileType = 0
        }
        api.UpdateUser(user.id, data).then((user) => updateUserInGlobalContext(user))
    }

    useEffect(() => {
        if (filterValue != 4 && query.has('tab')) {
            if (query.get('tab') === 'parametres') {
                setFilterValue(4)
            }
        }

    }, []);

    return(
        <div id={"profilemenu_page_wrapper"} className={user.profileType === Constant.PROFIL_TYPE_CANDIDAT ? "candidate":"recruiter"}>
            {!isMobile && <>
                {user.updatedAt && <ProfileHeader user={user} activeTab={filterValue} onSelectTab={(value) => {
                    setFilterValue(value)
                }} onChangeProfile={(type) => {
                    handleUserChange(type)
                }} setShowEnableProfile={() => setShowEnableProfile(true)} />}

                {filterValue === 0 &&
                    <ProfileDashboard mission={missions} />
                }
                {filterValue === 1 &&
                    <div className={"profile-container"}>
                        <ProfileMission />
                    </div>
                }
                {filterValue === 2 &&
                    <div className={"profile-container"}>
                        <Sponsorship />
                    </div>
                }
                {filterValue === 3 &&
                    <div className={"profile-container"}>
                        {user.subscription && <SubscriptionDashboard />}
                        {!user.subscription && <Subscriptions />}
                    </div>
                }
                {filterValue === 4 &&
                    <div className={"profile-container"}>
                        <Setting />
                    </div>
                }
            </>}
            {isMobile && <>
                <div className={"user_picture"}>
                    <div className={"picture"}>{user?.profilePicture && <img src={user.profilePicture}/> }</div>
                    <div className={"name"}>{user?.firstname} {user?.lastname}</div>
                </div>

                <nav>
                    <Link to={"/profile/user"}><span>P</span>Mon profil</Link>
                    {user.job === 0 && user.mission == 1 && user.profileType === Constant.PROFIL_TYPE_CANDIDAT &&
                        <Link to={"#0"} className={"profile_switch"} onClick={() => handleUserChange(Constant.PROFIL_TYPE_RECRUTEUR)}>Aller vers le profil<br/>"Je propose des missions"</Link>
                    }
                    {user.job === 0 && user.skill == 1 && user.profileType === Constant.PROFIL_TYPE_RECRUTEUR &&
                        <Link to={"#0"} className={"profile_switch"} onClick={() => handleUserChange(Constant.PROFIL_TYPE_CANDIDAT)}>Aller vers le profil<br/>"Je reçois des missions"</Link>
                    }
                    {user.job === 0 && user.mission == 0 && user.profileType === Constant.PROFIL_TYPE_CANDIDAT &&
                        <Link to={"#0"} className={""} onClick={() => setShowEnableProfile(true)}>Activer le profil je propose des missions</Link>
                    }
                    {user.job === 0 && user.skill == 0 && user.profileType === Constant.PROFIL_TYPE_RECRUTEUR &&
                        <Link to={"#0"} className={""} onClick={() => setShowEnableProfile(true)}>Activer le profil je reçois des missions</Link>
                    }
                    {/*<Link to={"/profile/company"}><span>C</span>Mon cabinet</Link>*/}
                    <Link to={"/profile/parrainage"}><span>P</span>Parrainage</Link>
                    <Link to={user.subscription ? "/mon-abonnement" : "/abonnements"}><span>A</span>Mon abonnement</Link>
                    <Link to={"/profile/parametres"}><span>P</span>Mes paramètres</Link>
                    {/*<Link to={"#0"}><span>L</span>Légal</Link>*/}
                    <Link to={"#0"} className={"logout"} onClick={logout}>Se déconnecter</Link>
                </nav>
            </>}
            <SlidingPanel show={showEnableProfile} handleClose={() => setShowEnableProfile(false)}>
                <div>
                    <div className={"bloc_title_info"}>
                        {user.profileType === Constant.PROFIL_TYPE_RECRUTEUR && <h2>Activer le profil je reçois des missions</h2>}
                        {user.profileType === Constant.PROFIL_TYPE_RECRUTEUR && <p>Afin de pouvoir recevoir des propositions de missions</p>}
                        {user.profileType === Constant.PROFIL_TYPE_CANDIDAT && <h2>Activer le profil je propose des missions</h2>}
                        {user.profileType === Constant.PROFIL_TYPE_CANDIDAT && <p>Vous êtes sur le point d'activer votre profil recruteur afin de pouvoir créer vos mission.</p>}
                    </div>
                    <div className={"text-center mb2 mt3"}>
                        <button className={"cta full blue"} onClick={() => {
                            enableProfile()
                            setShowEnableProfile(false)
                        }}>Activer</button>
                    </div>
                </div>
            </SlidingPanel>
        </div>
    )
}

export default ProfileMenu;