import {createBrowserRouter} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import UnprotectedRoute from "./UnprotectedRoute";

import Layout from '../_layout';
import ErrorPage from "../pages/404";

import Signin from "../pages/Signin";
import ForgotPassword from "../pages/ForgotPassword";
import Signup from "../pages/Signup";
import Dashboard from "../protected_pages/Dashboard";

import Missions from "../protected_pages/mission/Missions";
import SingleMission from "../protected_pages/mission/SingleMission";
import ProfileMenu from "../protected_pages/profil/ProfileMenu";
import ProfileMission from "../protected_pages/profil/ProfileMission";
import Notifications from "../protected_pages/Notification";
import SingleMatch from "../protected_pages/match/SingleMatch";

//Loaders
import {getMatch, getMissionMatchs, getMission, getMissions} from "../loaders/missionsLoader";
import {getUserProfileData} from "../loaders/dashboardLoader";
import {getUserStepStatus} from "../loaders/layoutLoader";
import {getNotifications} from "../loaders/notificationsLoader";
import Matchs from "../protected_pages/match/Matchs";
import {getHelpMatch, getMatchs, getMissionMatch, getSwitchMatch} from "../loaders/matchsLoader";
import Helps from "../protected_pages/help/Helps";
import {getHelp, getHelpMatchs, getHelps} from "../loaders/helpsLoader";
import SingleHelp from "../protected_pages/help/SingleHelp";
import SingleItemMatchsListing from "../protected_pages/_singleItem/SingleItemMatchsListing";
import Switchs from "../protected_pages/switch/Switchs";
import {getSwitch, getSwitchMatchs, getSwitchs} from "../loaders/switchsLoader";
import SingleSwitch from "../protected_pages/switch/SingleSwitch";
import {useEffect} from "react";
import CommunityRoute from "./CommunityRoute";
import SignupSSO from "../pages/SignupSSO";
import Conversations from "../protected_pages/message/Conversations";
import ConversationSingle from "../protected_pages/message/ConversationSingle";
import VideoChat from "../protected_pages/message/VideoChat";
import Subscriptions from "../protected_pages/subscription/Subscriptions";
import {getSubscriptions, getSubscription} from "../loaders/subscriptionsLoader";
import Questions from "../protected_pages/question/Questions";
import Acts from "../protected_pages/act/Acts";
import {getCommunity, getQuestions, getQuestion} from "../loaders/communityLoader";
import {getActs} from "../loaders/communityLoader";
import Community from "../protected_pages/Community";
import SubscriptionDashboard from "../protected_pages/subscription/SubscriptionDashboard";
import SubscriptionConfirmation from "../protected_pages/subscription/SubscriptionConfirmation";
import ResetPassword from "../pages/ResetPassword";
import SingleQuestion from "../protected_pages/question/SingleQuestion";
import Sponsorship from "../protected_pages/sponsorship/Sponsorship";
import Company from "../protected_pages/company/Company";
import Setting from "../protected_pages/setting/Setting";
import Email from "../protected_pages/setting/Email";
import Phone from "../protected_pages/setting/Phone";
import Password from "../protected_pages/setting/Password";
import {getConversation} from "../loaders/conversationLoader";

const router = (contextValue) => createBrowserRouter([
    {
        path: "/",
        element: <Layout/>,
        errorElement: <ErrorPage />,
        loader : () => getUserStepStatus(contextValue),
        shouldRevalidate: () => false,
        children : [
            {
                path: "download-app"
            },
            {
                path: "connexion",
                element : <UnprotectedRoute><Signin/></UnprotectedRoute>
            },
            {
                path: "forgot-password",
                element : <UnprotectedRoute><ForgotPassword/></UnprotectedRoute>
            },
            {
                path: "reset-password/:token",
                element : <UnprotectedRoute><ResetPassword/></UnprotectedRoute>
            },
            {
                path: "inscription",
                element : <UnprotectedRoute><Signup/></UnprotectedRoute>
            },
            {
                path: "inscription_sso",
                element : <UnprotectedRoute><SignupSSO/></UnprotectedRoute>
            },
            {
                path : "dashboard",
                element : <ProtectedRoute><Dashboard/></ProtectedRoute>,
                loader : () => getUserProfileData(contextValue),
                shouldRevalidate: () => false
            },
            //---------------
            //  MISSIONS
            //---------------
            {
                path : "missions",
                element : <ProtectedRoute><Missions/></ProtectedRoute>,
                loader : () => getMissions(contextValue),
                shouldRevalidate: () => false
            },
            {
                path : "missions/:missionId",
                element : <ProtectedRoute><SingleMission/></ProtectedRoute>,
                loader : getMission,
                shouldRevalidate: () => false
            },
            {
                path : "missions/:missionId/matchs",
                element : <ProtectedRoute><SingleItemMatchsListing/></ProtectedRoute>,
                loader : getMissionMatchs,
                shouldRevalidate: () => false
            },
            //---------------
            //  AIDE
            //---------------
            {
                path: "aides",
                element : <CommunityRoute><Helps/></CommunityRoute>,
                loader : () => getHelps(contextValue),
                shouldRevalidate: () => false
            },
            {
                path : "aides/:helpId",
                element : <CommunityRoute><SingleHelp/></CommunityRoute>,
                loader : getHelp,
                shouldRevalidate: () => false
            },
            {
                path : "aides/:helpId/matchs",
                element : <CommunityRoute><SingleItemMatchsListing/></CommunityRoute>,
                loader : getHelpMatchs,
                shouldRevalidate: () => false
            },
            //---------------
            //  SWITCH
            //---------------
            {
                path: "echanges",
                element : <CommunityRoute><Switchs/></CommunityRoute>,
                loader : () => getSwitchs(contextValue),
                shouldRevalidate: () => false
            },
            {
                path : "echanges/:switchId",
                element : <CommunityRoute><SingleSwitch/></CommunityRoute>,
                loader : getSwitch,
                shouldRevalidate: () => false
            },
            {
                path : "echanges/:switchId/matchs",
                element : <CommunityRoute><SingleItemMatchsListing/></CommunityRoute>,
                loader : getSwitchMatchs,
                shouldRevalidate: () => false
            },
            //---------------
            //  MATCHS
            //---------------
            {
                path: "matchs",
                element : <ProtectedRoute><Matchs/></ProtectedRoute>,
                loader : () => getMatchs(contextValue),
                shouldRevalidate: () => false
            },
            {
                path : "matchs/mission/:matchId",
                element : <ProtectedRoute><SingleMatch/></ProtectedRoute>,
                loader : getMissionMatch,
                shouldRevalidate: () => false
            },
            {
                path : "matchs/aide/:matchId",
                element : <ProtectedRoute><SingleMatch/></ProtectedRoute>,
                loader : getHelpMatch,
                shouldRevalidate: () => false
            },
            {
                path : "matchs/echange/:matchId",
                element : <ProtectedRoute><SingleMatch/></ProtectedRoute>,
                loader : getSwitchMatch,
                shouldRevalidate: () => false
            },
            //---------------
            //  PROFIL
            //---------------
            {
                path : "profile",
                element : <ProtectedRoute><ProfileMenu/></ProtectedRoute>,
                loader : () => getMissions(contextValue),
                shouldRevalidate: () => false
            },
            {
                path : "profile/user",
                element : <ProtectedRoute><ProfileMission/></ProtectedRoute>
            },
            {
                path : "notification",
                element : <ProtectedRoute><Notifications/></ProtectedRoute>,
                loader : getNotifications,
                shouldRevalidate: () => false
            },
            {
                path : "profile/parrainage",
                element : <ProtectedRoute><Sponsorship/></ProtectedRoute>
            },
            {
                path : "profile/company",
                element : <ProtectedRoute><Company/></ProtectedRoute>
            },
            {
                path : "profile/parametres",
                element : <ProtectedRoute><Setting/></ProtectedRoute>
            },
            {
                path : "profile/email",
                element : <ProtectedRoute><Email/></ProtectedRoute>
            },
            {
                path : "profile/phone",
                element : <ProtectedRoute><Phone/></ProtectedRoute>
            },
            {
                path : "profile/password",
                element : <ProtectedRoute><Password/></ProtectedRoute>
            },
            //---------------
            //  Twilio Message
            //---------------
            {
                path : "messagerie",
                element : <ProtectedRoute><Conversations/></ProtectedRoute>,
            },
            {
                path : "messagerie/:conversationId/:type",
                element : <ProtectedRoute><ConversationSingle/></ProtectedRoute>
            },
            {
                path : "messagerie/visio/:conversationId/:type",
                element : <ProtectedRoute><VideoChat/></ProtectedRoute>
            },
            //---------------
            //  Community
            //---------------
            {
                path : "communaute",
                element : <CommunityRoute><Community/></CommunityRoute>,
                loader : getCommunity
            },
            {
                path : "questions",
                element : <CommunityRoute><Questions/></CommunityRoute>,
                loader : getQuestions
            },
            {
                path : "acts",
                element : <CommunityRoute><Acts/></CommunityRoute>,
                loader : getActs
            },
            {
                path : "questions/:questionId",
                element : <CommunityRoute><SingleQuestion/></CommunityRoute>,
                loader : getQuestion
            },
            //---------------
            //  Subscription
            //---------------
            {
                path : "abonnements",
                element : <ProtectedRoute><Subscriptions/></ProtectedRoute>,
            },
            {
                path : "mon-abonnement",
                element : <ProtectedRoute><SubscriptionDashboard/></ProtectedRoute>,
                loader : getSubscription
            },
            {
                path : "abonnement/confirmation",
                element : <ProtectedRoute><SubscriptionConfirmation/></ProtectedRoute>
            }
        ]
    },
]);

export default router