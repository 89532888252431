import React, {useContext, useEffect, useState} from "react";
import {useSetState} from "react-use";
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";

import Api from "../../helper/api";
import {AuthContext} from "../../userAuth/AuthContext";

import {GenericEmptyItem} from "../../other/GenericBlock";
import EmployeeBlock from "./_EmployeeBlock";

const Company = () => {

    const [serverError, setServerError] = useState("")
    const [sponsorships, setSponsorships] = useState([])

    //user context
    const {login} = useContext(AuthContext);

    //API
    const api = new Api();

    const fetchData = async () => {
        setSponsorships(await api.getSponsorships())
    }

    //Load form data
    useEffect(() => {
        fetchData()
    }, [])

    //handle form
    const { register, formState: { errors }, watch, handleSubmit,setValue } = useForm();

    const handleFormSubmit = (data) => {
        const sponsorship = {
            "email" : data.mail,
            "lastname" : data.lastname,
            "firstname": data.firstname,
            "phone": data.phone,
        }
        createSponsorship(sponsorship)
    }
    const createSponsorship = async (data) => {
        setServerError("");

        await api.createSponsorship(data).then((res) => {
            fetchData()
        }).catch(error => {
            setServerError(error.message);
        })
    }

    return(
        <div id={"sponsorship_wrapper"} className={"profile-wrapper"}>
            {/*<h1>Retrouvez ici tous vos parrainages</h1>*/}
            {/*<div className={"sponsorship_box"}>*/}
            {/*    <div className={"side-left"}>*/}
            {/*        {sponsorships?.length > 0 && <div className={"sponsorship_list"}>*/}
            {/*            <h2>Vos parrainages</h2>*/}
            {/*            {sponsorships.map( (sponsorship, key) => <SponsorshipBlock {...sponsorship} key={`sponsorship_${sponsorship.id}`} />)}*/}
            {/*        </div>}*/}
            {/*        {sponsorships?.length === 0 && <GenericEmptyItem title={"Aucun parrainage en cours"}/>}*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

export default Company