import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import Api from "../helper/api";
import { Base64 } from 'js-base64';
import axios from "axios";
import {create} from "apisauce";
import {fromAddress, setDefaults} from "react-geocode";
import {useOutletContext} from "react-router-dom";

const CreateCompany = ({registrationNumber, handleClose, company}) => {

    const {register,formState: { errors }, handleSubmit, reset, setValue} = useForm();
    const {user} = useOutletContext();
    const api = new Api();
    const [name, setName] = useState(null)
    const [address, setAddress] = useState(null)
    const [location, setLocation] = useState(null)
    const [error, setError] = useState("")
    const [line, setLine] = useState(null)
    const [line2, setLine2] = useState(null)
    const [city, setCity] = useState(null)
    const [zipcode, setZipcode] = useState(null)
    const [country, setCountry] = useState(null)

    const handleFormSubmit = (data) => {
        handleClose({name, registrationNumber, address, location, line, line2, city, zipcode, country})
    }

    const loadInseeInfo = () => {
        const data = {
            siret: registrationNumber
        }
        api.postCompanyInsee(data).then(async (response) => {
            const company = response.etablissement
            const addr = company.adresseEtablissement
            let addressStr = addr.numeroVoieEtablissement + ' ' + addr.typeVoieEtablissement + ' ' + addr.libelleVoieEtablissement + ' ' + addr.codePostalEtablissement + ' ' + addr.libelleCommuneEtablissement

            if (company.uniteLegale.denominationUniteLegale) {
                setName(company.uniteLegale.denominationUniteLegale)
            } else if (company.uniteLegale.nomUniteLegale || company.uniteLegale.nomUsageUniteLegale || company.uniteLegale.prenom1UniteLegale) {
                let name = `${company.uniteLegale.nomUniteLegale} ${company.uniteLegale.nomUsageUniteLegale} ${company.uniteLegale.prenom1UniteLegale}`
                setName(name.trim())
            }
            setAddress(addressStr)
            setLine(addr.numeroVoieEtablissement + ' ' + addr.typeVoieEtablissement + ' ' + addr.libelleVoieEtablissement)
            setLine2(addr.complementAdresseEtablissement)
            setCity(addr.libelleCommuneEtablissement)
            setZipcode(addr.codePostalEtablissement)
            setCountry('api/countries/86')
            setError('')

            setDefaults({
                key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
                language: "fr",
                region: "fr",
            });
            const locationResponse = await fromAddress(addressStr)
            if (locationResponse && locationResponse.results) {
                setLocation(locationResponse.results[0]?.geometry?.location)
            }
        }).catch(error => {
            setError('Siret introuvable')
        })
    }

    useEffect(() => {
        if (company && company.id === -1) {
            loadInseeInfo()
        }
    }, [company])

    return (
        <div id={"create_company_wrapper"}>
            <div className={"bloc_title_info"}>
                <h2>{user.job === 0 ? 'Créer cabinet' : 'Créer entreprise'}</h2>
            </div>

            {name && <form onSubmit={handleSubmit(handleFormSubmit)} className={"profil_block"}>
                <div className={"form_row_wrapper type3"}>
                    <label>Siret</label>
                    <input type={"text"} name={"siret"} id={"siret"} disabled value={registrationNumber} />
                </div>
                <div className={"form_row_wrapper type3"}>
                    <label>Nom</label>
                    <input type={"text"} name={"name"} id={"name"} disabled value={name} />
                </div>
                <div className={"form_row_wrapper type3"}>
                    <label>Adresse</label>
                    <input type={"text"} name={"address"} id={"address"} disabled value={address} />
                </div>
                <div className={"cta_wrapper"}>
                    <button className={"cta blue full"}>{user.job === 0 ? 'Créer cabinet' : 'Créer entreprise'}</button>
                </div>
            </form>}
            {!name && <div className={"error text-center"}>{error}</div>}

        </div>
    )
}

export default CreateCompany