import React, {useEffect} from "react";
import {Link, useNavigate, useOutletContext} from "react-router-dom";
import ItemLabel from "../../other/ItemLabel";
import {useSetState} from "react-use";
import {
    PROFIL_TYPE_RECRUTEUR,
    STEP_AGREEMENT,
    STEP_DONE,
    STEP_PENDING,
    STEP_RECIPIENT_ACCEPTED, STEP_RECIPIENT_REFUSED,
    STEP_SENDER_ACCEPTED,
    STEP_SENDER_REFUSED
} from "../../other/Constant";
import {GetHelpDate, getIcon, truncateString} from "../../helper/other";
import moment from "moment";

const MissionBlock = (props) => {

    const {user} = useOutletContext();
    const navigate = useNavigate();
    const [state, setState] = useSetState({
        completion : "",
        status : "",
        class: ''
    })

    useEffect(() => {
        let step = props.linking?.step
        if (step === null || typeof step === 'undefined') {
            step = props.step
        }

        if (props.closed) {
            setState({completion : "100%", status : "Terminée", class: 'step-done'})
        } else {
            // if (props.linking) {
            //     console.log(props.linking?.step, "STEP")
            //     console.log(step, "STEP")
            // }
            switch(step) {
                case STEP_PENDING :
                    setState({completion : "0%", status : "Nouveau match", class: 'step-pending'})
                    break;
                case STEP_RECIPIENT_ACCEPTED :
                    setState({completion : "25%", status : "Matching en attente", class: 'step-sent'})
                    break;
                case STEP_RECIPIENT_REFUSED :
                    setState({completion : "", status : "Sans suite", class: 'step-declined'})
                    break;
                case STEP_SENDER_ACCEPTED :
                    setState({completion : "50%", status : "Mise en relation", class: 'step-accepted'})
                    break;
                case STEP_SENDER_REFUSED :
                    setState({completion : "", status : user.profileType == PROFIL_TYPE_RECRUTEUR ? "Pas intéressé" : "Sans suite", class: 'step-declined'})
                    break;
                case STEP_AGREEMENT :
                    setState({completion : "75%", status : "Accord", class: 'step-agreement'})
                    break;
                case STEP_DONE :
                    setState({completion : "100%", status : "Terminée", class: 'step-done'})
                    break;
                default :
                    // setState({completion : "0%", status : "Nouveau match", class: 'step-pending'})
            }
        }
    },[])

    return(
        <div className={`mission_block_item`} onClick={() => {
            if (props.onClick) {
                props.onClick()
            }else if (props.link) {
                navigate(props.link, { state: { title: props.title }})
            } else {
                navigate(`/missions/${props.id}`)
            }
        }}>
            {/*{!props.linking && props.step !== null && <div className={`step ${state.class}`}>{state.status}</div>}*/}
            <div className={'mission_block_item_inner'}>
                <div className={`item_label item_label_${getIcon(props.id)}`} />
                {/*{props.type?.code && <ItemLabel code={props.type?.code} type={props.type.name}/>}*/}
                <div className={"content"}>
                    <div className={"title"}>{props.title}</div>
                    {/*<div className={"title"}>{truncateString(props.title, 80)}</div>*/}
                    <div className={"subtitle"}>{props.type.name}</div>
                    <div className={"description"}>
                        <span className={"reference"}>{props.reference}</span>
                        {state.status !== "" && <span className={"state"}>{props.showLinkingNumber && props.linking ? `#${props.linking.number} ` : ''}{state.status}</span>}
                    </div>
                    <div className={"progression"}>
                        {/*{state.completion}*/}
                    </div>
                    {/*<div className={"detail d-mobile-none"}>{props.description}</div>*/}
                </div>
            </div>
            <span className={"date"}>{moment(props.createdOn).format('DD/MM/YYYY')}</span>
        </div>
    )
}

MissionBlock.defaultProps = {
    createdOn : "1970-01-01 00:00:00",
    title : "Default title",
    description : "Default body",
    status : "",
    unread_match : "0",
    people : "0",
    estimatedDuration : {name : "NA"},
    limitDate : "NA",
    notification : "",
    showCta : true,
    showNotification : true
}

export default MissionBlock