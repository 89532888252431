import React, {useState, useEffect, useCallback, useContext} from "react";
import {Link, useNavigate, useOutletContext, useRevalidator} from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSetState } from "react-use";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {useDropzone} from "react-dropzone";
import CurrencyInput from 'react-currency-input-field';

import Api from "../../helper/api";
import ProfilDataBlock from "../../other/ProfilDataBlock";
import AddressAutoComplete from "../../form/addressAutoComplete";
import FormExperience from "../../form/formExperience";
import FormSkill from "../../form/formSkill";
import FormSpecialization from "../../form/formSpecialization";
import FormActivity from "../../form/formActivity";
import FormJob from "../../form/formJob";
import FormEducation from "../../form/formEducation";
import FormAssociation from "../../form/formAssociation";
import MultiSelect from "../../form/multiSelect";
import SlidingPanel from "../_sliding_panel";
import CreateCompany from "../../slidingContent/createCompany";
import JoinCompany from "../../slidingContent/joinCompany";
import {AuthContext} from "../../userAuth/AuthContext";
import * as Constant from "../../other/Constant";
import moment from "moment";
import {getValue} from "@testing-library/user-event/dist/utils";
import CommunityRoute from "../../route/CommunityRoute";
import * as mime from 'react-native-mime-types';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Document, pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const ProfileMission = (props) => {

    const {user} = useOutletContext();
    const {updateUserInGlobalContext} = useContext(AuthContext)
    const [userData, setUserData] = useState(user)
    const [countries, setCountries] = useState([])
    const [languages, setLanguages] = useState([])
    const [showRadius, setShowRadius] = useState(false)
    const [showDistance, setShowDistance] = useState(false)
    const [companies, setCompanies] = useState([])
    const [company, setCompany] = useState(null)
    const [companyInputValue, setCompanyInputValue] = useState(user.company ? user.company.name : "")
    const [errorCertificate, setErrorCertificate] = useState(false)
    const [errorCompany, setErrorCompany] = useState(false)
    const [showProfileUpdated, setShowProfileUpdated] = useState(false)
    const [showCommunityPolicy, setShowCommunityPolicy] = useState(false)
    const [docs, setDocs] = useState([])
    const [showFile, setShowFile] = useState(false)
    const [errorSkill, setErrorSkill] = useState(false)

    const navigate = useNavigate()

    //handle form
    const {register,formState: { errors }, trigger, handleSubmit, setValue, getValues, watch, unregister} = useForm()
    // const {register : registerPresentation ,formState: { errors : errorsPresentation }, handleSubmit : handleSubmitPresentation, setValue : setValuePresentation} = useForm()

    //handle form submit
    let revalidator = useRevalidator();

    //date picker
    const [datePicker, setDatePicker] = useState("");

    //Professionnal card upload
    const [professionnalCard, setProfessionnalCard] = useSetState({
        name : "",
        fileObject : null
    });

    const handleFileDrop = useCallback(acceptedFiles => {
        setProfessionnalCard({
            fileObject : acceptedFiles[0],
            name : acceptedFiles[0].name
        })
        api.setUserProfessionnalCard(acceptedFiles[0]).then(response => {
            setUserData({...userData, professionalCertificateName: response.url})
            setUserData({...userData, professionalCertificateStatus: 1})

            if (response.url){
                console.log(response.url, 'CHECK URL')
                setProfessionnalCard({name : response.url})
                setDocs([...docs, { uri: response.url, fileType: mime.lookup(response.url)}])
            }

        })
    }, [])

    const removeFile = () => {
        setProfessionnalCard({
            name: '',
            fileObject: null
        })
        api.deleteUserProfessionnalCard().then(data => {
            setUserData({...userData, professionalCertificateName: null})
            setUserData({...userData, professionalCertificateStatus: null})
        })
    }

    const {getRootProps, getInputProps} = useDropzone({
        onDrop : handleFileDrop,
        accept : {
            'image/*': ['.png','.jpg','.jpeg'],
            'application/pdf': ['.pdf'],
        }
    })

    //Avatar upload
    const handleAvatarUpload = async (e) => {
        if (e.target.files[0] !== undefined) {
            api.setUserAvatar(e.target.files[0]).then(response => {
                setUserData({...userData, imageName: response.url})
                updateUserInGlobalContext(userData)
                revalidator.revalidate()
            })
        }
    }

    //api
    const api = new Api();

    //form data
    const [formData, setFormData] = useSetState({
        barList : [],
        countryList : [],
        langList : [],
        experienceYear : [],
        disponibilityList : [],
        collaborationList : [],
        radiusList : [],
        positionList : [],
    })

    //form update
    const handleUpdateDetails = async (data) => {
        // console.log(data)
        setErrorCertificate(false)
        setErrorCompany(false)
        setErrorSkill(false)

        if (user.job === 0 && (professionnalCard.name === null || professionnalCard.name === '')) {
            setErrorCertificate(true)
            const element = document.getElementById('certificate');
            element?.scrollIntoView({
                behavior: 'smooth'
            });
            return
        }
        if (!data.location || data.location === '') {
            setErrorCompany(true)
            const element = document.getElementById('company');
            element?.scrollIntoView({
                behavior: 'smooth'
            });
            return
        }
        if (user.job == 0 && user.profileType !== 0 && (!user.skills || user.skills.length === 0)) {
            setErrorSkill(true)
            return
        }

        const is_valid = await trigger()
        let postData = data

        if (is_valid){
            postData.countries = countries
        }
        postData.languages = languages
        if (parseInt(getValues('radius')) > 0) {
            postData.radius = parseInt(getValues('radius'))
        }
        if (parseInt(getValues('distance')) > 0) {
            postData.distance = parseInt(getValues('distance'))
        }
        if (postData.experience == "") {
            postData.experience = null
        }
        if (postData.collaborationType == "") {
            postData.collaborationType = null
        }
        if (postData.available == "") {
            postData.available = null
        }
        if (user.job === 0 && user.profileType === Constant.PROFIL_TYPE_CANDIDAT) {
            if (postData.averageHourlyRate && typeof postData.averageHourlyRate === 'string' || postData.averageHourlyRate instanceof String) {
                postData.averageHourlyRate = postData.averageHourlyRate.replace(/[^0-9.-]+/g, "")
            }
            postData.averageHourlyRate = parseFloat(postData.averageHourlyRate)

            if (postData.dailyRate && typeof postData.dailyRate === 'string' || postData.dailyRate instanceof String) {
                postData.dailyRate = postData.dailyRate.replace(/[^0-9.-]+/g, "")
            }
            postData.dailyRate = parseFloat(postData.dailyRate)

            if (postData.halfDayRate && typeof postData.halfDayRate === 'string' || postData.halfDayRate instanceof String) {
                postData.halfDayRate = postData.halfDayRate.replace(/[^0-9.-]+/g, "")
            }
            postData.halfDayRate = parseFloat(postData.halfDayRate)
        }
        console.log(postData, 'CHECK DATA')
        postData.updatedAt = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        setUserData({...userData, data })

        api.UpdateUser(user.id, postData).then((u) => {
            updateUserInGlobalContext(u)
            setShowProfileUpdated(true)
        })
    }

    useEffect(() => {
        if (user.job === 0) {
            setValue("bar",user.bar ? user.bar.id: null)
            setValue("communityPolicy", user.communityPolicy)

            if (user.profileType === Constant.PROFIL_TYPE_CANDIDAT) {
                setValue("experience",user.experience ? user.experience.id: null)
                setValue('collaborationType', user.collaborationType ? user.collaborationType.id : null,{shouldValidate: true})
                setValue('available', user.available.id??"")
            }
        } else if (user.job === 1) {
            setValue('position', user.position ? user.position.id : null)
        }
        setCountries(user.countries)
        setLanguages(user.languages)

    }, [formData])

    useEffect(() => {

        const fetchData = async () => {
            //TEST ONLY
            // await Api.getUserTest()

            //load api data
            const barList = await api.getBarList();
            const countryListData = await api.getCountryList();
            const experienceYear = await api.getExperienceYearList();
            const langList = await api.getLanguageList();
            const disponibilityListData = await api.getAvailability();
            const collaborationTypeListData = await api.getMissionCollaborationTypesList();
            const radiusListData = await api.getRadiusList();
            const positionList = await api.getPositionList();

            setFormData({
                barList : barList,
                countryList: countryListData,
                experienceYear: experienceYear,
                langList : langList,
                disponibilityList : disponibilityListData,
                collaborationList : collaborationTypeListData,
                radiusList: radiusListData,
                positionList : positionList
            })
        }
        console.log(user, 'USER CHECK')

        //set value of personnal details form
        setValue("firstname",user.firstname??"")
        setValue("lastname",user.lastname??"")
        setValue("phone",user.phone??"")
        setValue("email",user.email??"")
        setValue("location",user.company ? user.company.location : "")
        setValue("company", user.company)
        setValue("companyRegistrationNumber",user.company ? user.company.registrationNumber : "")
        setValue("companyName",user.company ? user.company.name : "")

        if (user.job === 0) {
            setValue("oathDate", user.oathDate ?? "")
            setDatePicker(user.oathDate ? new Date(user.oathDate) : "") //set datepicker value

            if (user.professionalCertificateName){
                setProfessionnalCard({name : user.professionalCertificateName})
                // setDocs([...docs, { uri: process.env.REACT_APP_API_URL+"/"+user.professionalCertificateName, fileType: mime.lookup(user.professionalCertificateName)}])
                // setDocs([...docs,
                //     {
                        // uri: "https://code.visualstudio.com/shortcuts/keyboard-shortcuts-macos.pdf",
                        // fileType: "application/pdf",
                        // fileName: "macos.pdf"
                    //     uri: user.professionalCertificateName,
                    //     fileType: mime.lookup(user.professionalCertificateName)
                    // }])
            }

            if (user.profileType === Constant.PROFIL_TYPE_CANDIDAT) {
                setValue("averageHourlyRate",user.averageHourlyRate??"")
                setValue("dailyRate",user.dailyRate??"")
                setValue("halfDayRate",user.halfDayRate??"")
                setValue("description",user.description ?? "")
                setValue("website", user.website??"")
                setValue("videoChannel", user.videoChannel??"")
            }
        }
        fetchData()
    },[])

    //handle change of collaboration type field
    useEffect(() => {

        const colType = parseInt(watch("collaborationType"))

        if(colType === 1 || colType === 3){
            setShowRadius(true)
            setValue("radius", user.radius)
        }else{
            setShowRadius(false)
            unregister("radius")
            unregister("distance")
        }
    }, [watch("collaborationType")])


    //handleRadius Change
    useEffect(() => {

        const radius = parseInt(watch("radius"))

        if(radius === 2){
            setShowDistance(true)
            setValue("distance", user.distance)
        }else{
            setShowDistance(false)
            unregister("distance")
        }
    }, [watch("radius")])


    const search = (event) => {
        const value = event.target.value.replace(/\s/g, '')
        setCompanyInputValue(value)
        if (value.length >= 14) {
            const data = {
                registrationNumber: value
            }
            api.getCompanies(data).then(companies => {
                let companyEmpty = [{id: -1, name: user.job === 0 ? "Cliquez ici pour ajouter votre cabinet" : "Cliquer ici pour ajouter votre entreprise"}];
                setCompanies(companyEmpty.concat(companies))
            })
        } else{
            setCompanies([])
        }
    }

    const createNewCompany = (company) => {
        if (company.name.length > 0) {
            const data = {
                name: company.name,
                registrationNumber: company.registrationNumber,
                location: company.address,
                latitude: company.location?.lat,
                longitude: company.location?.lng,
                line: company.line,
                line2: company.line2,
                city: company.city,
                zipcode: company.zipcode,
                country: company.country
            }
            console.log(data, 'BEFORE CREATE NEW COMPANY')
            api.postCompany(data).then(company => {
                console.log(company, 'NEW COMPANY CREATED AND JOINED')
                setCompany(null)
                setCompanies([])
                setErrorCompany(false)
                setUserData({...userData, company: company, location: company.location, latitude: company.latitude, longitude: company.longitude })
                setValue("companyRegistrationNumber", company.registrationNumber)
                setValue("companyName", company.name)
                setValue("location", company.location)
            })
        }
    }

    const joinCompany = (company) => {
        const data = {
            company: 'api/companies/' + company.id,
            user: 'api/users/' + user.id
        }
        api.postCompanyEmployee(data).then(data => {
            console.log(data.company, 'JOIN COMPANY YES')
            setCompany(null)
            setCompanies([])
            setErrorCompany(false)
            setUserData({...userData, location: data.company.location, latitude: data.company.latitude, longitude: data.company.longitude })
            setValue("companyRegistrationNumber", data.company.registrationNumber)
            setValue("companyName", data.company.name)
            setValue("location", data.company.location)
        })
    }

    return(
        <div id={"profile_wrapper"} className={"mission_profile"}>
            {/*{user.verified && user.profileType >= 0 && 'updatedAt' in props.user && */}
            <div className={"bloc_title_info"}>
                <h2>Bienvenue sur EsterLaw, {user.firstname}</h2>
                {user.job === 0 && user.profileType === Constant.PROFIL_TYPE_RECRUTEUR && <p>Pour proposer des missions et accéder à la communauté, veuillez nous communiquer les informations ci-dessous.</p>}
                {user.job === 0 && user.profileType === Constant.PROFIL_TYPE_CANDIDAT && <p>Pour un maximum de matchs, présentez un profil très complet en quelques clics et/ou accéder à la communauté en complétant les champs obligatoires.</p>}
                {user.job === 1 && <p>Pour proposer des missions, veuillez nous communiquer les informations ci-dessous.</p>}
                <p>Assurez-vous de cliquer sur 'Enregistrer le profil' après avoir rempli chaque champ pour sauvegarder vos données.</p>
            </div>
            <form onSubmit={handleSubmit(handleUpdateDetails)}>

                <div id={"personnal_information_details"} className={"profil_block"}>
                    <div className={"upload_wrapper form_row_wrapper"} id={"avatar_upload_wrapper"}>
                        <input type={"file"} name={"profil_picture"} id={"profil_picture"} accept="image/png, image/jpeg" onChange={handleAvatarUpload}/>
                        <label htmlFor={"profil_picture"} className={"picture"}>
                            {user.profilePicture && <img src={user.profilePicture}/>}
                            {!user.profilePicture && <div>{user.firstname.charAt(0)}{user.lastname.charAt(0)}</div>}
                        </label>
                        {/*<label htmlFor={"profil_picture"}>Télécharger une photo</label>*/}
                        {/*<button disabled={user.profilePicture ? false: true}>Supprimer</button>*/}
                    </div>
                    <div className={"form_row_wrapper type3"}>
                        <label>Prénom *</label>
                        <input type={"text"} name={"firstname"} id={"firstname"} placeholder={"Prénom"} {...register("firstname",{required : "Champs obligatoire"})}/>
                        <div className={"error"}>{errors.firstname && errors.firstname.message}</div>
                    </div>
                    <div className={"form_row_wrapper type3"}>
                        <label>Nom *</label>
                        <input type={"text"} name={"lastname"} id={"lastname"} placeholder={"Nom"} {...register("lastname",{required : "Champs obligatoire"})}/>
                        <div className={"error"}>{errors.lastname && errors.lastname.message}</div>
                    </div>
                    <div className={"form_row_wrapper type3"}>
                        <label>Email *</label>
                        <input type={"email"} name={"email"} id={"email"} className={"text_disabled"} placeholder={"e-mail"} {...register("email",{required : "Champs obligatoire"})} disabled />
                        <div className={"error"}>{errors.email && errors.email.message}</div>
                    </div>
                    <div className={"form_row_wrapper type3"}>
                        <label>Numéro de téléphone *</label>
                        <input type={"text"} name={"phone"} id={"phone"} className={"text_disabled"} placeholder={"Numéro de téléphone"} {...register("phone",{required : "Champs obligatoire"})} disabled/>
                        <div className={"error"}>{errors.phone && errors.phone.message}</div>
                    </div>
                    {user.job === 0 && <>
                        <div className={"form_row_wrapper type3"}>
                            <label>Barreau actuel *</label>
                            <select name={"bar"} id={"bar"} placeholder={"Sélectionnez votre barreau"} defaultValue={""} {...register("bar", {required: "Champs obligatoire"})}>
                                <option value={""} disabled>Barreau actuel</option>
                                {formData.barList.map((item) => {
                                    return (<option value={item.id} key={item.name + "_" + item.id}>{item.name}</option>)
                                })}
                            </select>
                            <div className={"error"}>{errors.bar && errors.bar.message}</div>
                        </div>
                        <div className={"form_row_wrapper type3 dropzone_wrapper"} id="certificate">
                            {professionnalCard.name === "" &&
                                <div {...getRootProps()} className={"dropzone"}>
                                    <input {...getInputProps()} name={"professionalCertificate"}/>
                                    Ajouter carte professionnelle <br/>ou attestation de l'ordre
                                </div>
                            }
                            {professionnalCard.name !== "" && <>
                                <div className={"dropzone_notice text-center success"}>
                                    Carte ajoutée
                                </div>
                                <div className={"dropzone_files text-center"}>
                                    {/*<a href={"#0"} onClick={() => setShowFile(true)}>{professionnalCard.name}</a> - <a href={'#0'} onClick={() => { removeFile() }}>supprimer</a>*/}
                                    <a href={professionnalCard.name} target="_blank">{professionnalCard.name.replace('https://esterlaw-professional-certificate.s3.eu-west-3.amazonaws.com/', '')}</a> - <a href={'#0'} onClick={() => { removeFile() }}>supprimer</a>
                                </div>
                            </>}
                            {errorCertificate && <div className={"error text-center"}>Carte professionnelle manquante</div>}
                        </div>

                        <div className={"form_row_wrapper type3"}>

                            <label>Date de prestation de serment *</label>
                            <input type={"hidden"} name={"oathDate"} id={"oathDate"} {...register("oathDate", {required: "Champs obligatoire"})}/>

                            <DatePicker
                                showYearDropdown
                                yearDropdownItemNumber={30}
                                placeholderText="Date de prestation de serment"
                                dateFormat="dd/MM/yyyy"
                                maxDate={new Date()}
                                selected={datePicker} onChange={(date) => {
                                setDatePicker(date??"")
                                setValue("oathDate", date?date.toISOString():"", { shouldValidate: true })
                            }}/>
                            <div className={"error"}>{errors.oathDate && errors.oathDate.message}</div>
                        </div>
                    </>}
                    {user.job === 1 &&
                        <div className={"form_row_wrapper type3"}>
                            <label>Fonction</label>
                            <select name={"position"} id={"position"} placeholder={"Fonction"} defaultValue={""} {...register("position")}>
                                <option value={""}>Fonction</option>
                                {formData.positionList.map((item) => {
                                    return (<option value={item.id} key={item.name + "_" + item.id}>{item.name}</option>)
                                })}
                            </select>
                            <div className={"error"}>{errors.position && errors.position.message}</div>
                        </div>}
                    <div className={"form_row_wrapper type3"}>
                        {user.job === 0 && <label>Nº immatriculation du cabinet (Siret) *</label>}
                        {user.job !== 0 && <label>Nº immatriculation de l'entreprise (Siret) *</label>}
                        <input type={"text"} name={"companyRegistrationNumber"} id={"companyRegistrationNumber"} placeholder={user.job === 0 ? "Nº immatriculation du cabinet (Siret)" : "Nº immatriculation de l'entreprise (Siret)"} {...register("companyRegistrationNumber", {required: "Champs obligatoire", onChange:search})}/>
                        <div className={"error"}>{errors.companyRegistrationNumber && errors.companyRegistrationNumber.message}</div>
                    </div>
                    {getValues("companyName") && <div className={"form_row_wrapper type3"}>
                        {user.job === 0 && <label>Nom du cabinet *</label>}
                        {user.job !== 0 && <label>Nom de l'entreprise *</label>}
                        <input type={"text"} name={"companyName"} id={"companyName"} disabled placeholder={"Nom"} {...register("companyName")}/>
                    </div>}
                    {companies.length > 0 && <div className={"company_list"} id="company">
                        {companies.map(c => {
                            return (
                                <div className={`company_item ${c.id == -1 ? 'company_item_add cta full blue' : ''}`} onClick={() => {
                                    setCompany(c)
                                }}>
                                    <p>{c.name}</p>
                                </div>
                            )
                        })}
                        {errorCompany && <div className={"error text-center"}>Vous devez sélectioner votre cabinet ou l'ajouter</div>}
                    </div>}

                    {getValues("location") && <div className={"form_row_wrapper type3"}>
                        <label>Adresse professionnelle *</label>
                        <input type={"text"} name={"location"} id={"location"} disabled placeholder={"Adresse professionnelle"} {...register("location")}/>
                    </div>}
                </div>

                {user.job === 0 && user.profileType === Constant.PROFIL_TYPE_CANDIDAT && <>
                    <div id={"average"} className={"profil_block"}>
                        <div className={"form_row_wrapper type3"}>
                            <label>Taux horaire minimum indicatif</label>
                            <CurrencyInput
                                id={"averageHourlyRate"}
                                name={"averageHourlyRate"}
                                placeholder={"Taux horaire minimum indicatif"}
                                allowDecimals={false}
                                defaultValue={user.averageHourlyRate}
                                suffix={" €"}
                                intlConfig={{ locale: 'fr-FR', currency: 'EUR' }}
                                allowNegativeValue={false}
                                {...register("averageHourlyRate")}
                            />
                            {/*<input type={"text"} name={"averageHourlyRate"} id={"averageHourlyRate"} placeholder={"Taux horaire moyen"} {...register("averageHourlyRate")}/>*/}
                            {/*<div className={"error"}>{errors.averageHourlyRate && errors.averageHourlyRate.message}</div>*/}
                        </div>
                        <div className={"form_row_wrapper type3"}>
                            <label>Tarif journalier indicatif</label>
                            <CurrencyInput
                                id={"dailyRate"}
                                name={"dailyRate"}
                                placeholder={"Taux journalier indicatif"}
                                allowDecimals={false}
                                defaultValue={user.dailyRate}
                                suffix={" €"}
                                intlConfig={{ locale: 'fr-FR', currency: 'EUR' }}
                                allowNegativeValue={false}
                                {...register("dailyRate")}
                            />
                            {/*<input type={"text"} name={"dailyRate"} id={"dailyRate"} placeholder={"Tarif journalier"} {...register("dailyRate")}/>*/}
                            {/*<div className={"error"}>{errors.dailyRate && errors.dailyRate.message}</div>*/}
                        </div>
                        <div className={"form_row_wrapper type3"}>
                            <label>Tarif demi-journée indicatif</label>
                            <CurrencyInput
                                id={"halfDayRate"}
                                name={"halfDayRate"}
                                placeholder={"Taux demi-journée indicatif"}
                                allowDecimals={false}
                                defaultValue={user.halfDayRate}
                                suffix={" €"}
                                intlConfig={{ locale: 'fr-FR', currency: 'EUR' }}
                                allowNegativeValue={false}
                                {...register("halfDayRate")}
                            />
                            {/*<input type={"text"} name={"halfDayRate"} id={"halfDayRate"} placeholder={"Tarif demi-journée"} {...register("halfDayRate")}/>*/}
                            {/*<div className={"error"}>{errors.halfDayRate && errors.halfDayRate.message}</div>*/}
                        </div>
                    </div>

                    <div id={"experience_details"} className={"profil_block"}>

                        <div className={"bloc_title_info"}>
                            <h2>Expériences</h2>
                            <p>Mettez en avant votre expérience, que vous soyez jeune avocat(e) ou expérimenté(e), avec une expertise internationale ou pas, notre algorithme vous proposera les missions qui vous correspondent.</p>
                        </div>

                        <div className={"form"}>
                            <div className={"form_row_wrapper type3"}>
                                <label>Expérience</label>
                                <select name={"experience"} id={"experience"} defaultValue={""} {...register("experience")}>
                                    <option value={""}>Expérience</option>
                                    {formData.experienceYear.map((item) => {
                                        return (<option value={item.id} key={item.name + "_" + item.id}>{item.name}</option>)
                                    })}
                                </select>
                                {/*<div className="error">{errors.experience && errors.experience.message}</div>*/}
                            </div>

                            <div className={"form_row_wrapper type3 multiselect"}>
                                <label>Langues</label>
                                <MultiSelect name={"langues"} id={"langues"} placeholder={"Langues"} options={formData.langList.map(item => ({label: item.name, value: item.id}))} isMulti={true} closeMenuOnSelect={false} defaultValues={languages} getValues={setLanguages}/>
                            </div>

                            <div className={"form_row_wrapper type3 multiselect"}>
                                <label>Expertise internationale</label>
                                <MultiSelect name={"expertise"} id={"expertise"} placeholder={"Expertise internationale"} options={formData.countryList.map(item => ({label: item.name, value: item.id}))} isMulti={true} closeMenuOnSelect={false} defaultValues={countries} getValues={setCountries}/>
                            </div>
                        </div>
                    </div>

                    <div id={"presentation_details"} className={"profil_block"}>
                        <div className={"bloc_title_info"}>
                            <h2>Présentation : parlez-nous de vous</h2>
                            <p>Votre présentation est la pierre angulaire du match, donnez en quelques lignes à votre profil un maximum de chances lors des matchs</p>
                        </div>
                        <div className={"form_row_wrapper type3"}>
                            <textarea name={"description"} placeholder={"Quelques mots..."} {...register("description")}></textarea>
                            {/*<div className="error">{errors.description && errors.description.message}</div>*/}
                        </div>
                    </div>

                    <div id={"availability_details"} className={"profil_block"}>
                        <div className={"bloc_title_info"}>
                            <h2>Disponibilité</h2>
                            <p>Optez pour le mode de travail de votre choix. Par défaut, notre algorithme vous considèrera disponible. Par la suite, pensez à signaler vos périodes d'indisponibilité dans le menu profil.</p>
                        </div>

                        <div className={"form_row_wrapper type3"}>
                            <label>Choisir un mode de collaboration</label>
                            <select name={"collaborationType"} id={"collaboration_type"}  {...register("collaborationType")}>
                                <option value={""}>Type de collaboration</option>
                                {formData.collaborationList.map((item) => {
                                    return (<option value={item.id} key={item.name + "_" + item.id}>{item.name}</option>)
                                })}
                            </select>
                        </div>

                        {showRadius &&
                            <>
                                <div className={"form_row_wrapper type3"}>
                                    <label>Périmètre</label>
                                    <select name={"radius"} id={"radius"} {...register("radius", {required: "Veuillez renseigner ce champs pour continuer"})}>
                                        {formData.radiusList.map((item) => {
                                            return (<option value={item.id} key={item.name + "_" + item.id}>{item.name}</option>)
                                        })}
                                    </select>
                                </div>

                                {showDistance &&
                                    <div className={"form_row_wrapper type3"}>
                                        <label>Distance (km)</label>
                                        <input type={"text"} name={"distance"} id={"distance"} {...register("distance", {required: "Veuillez renseigner ce champs pour continuer"})}/>
                                    </div>
                                }
                            </>
                        }

                        <div className={"form_row_wrapper type3"}>
                            <label>Je suis disponible</label>
                            <select name={"available"} id={"available"}  {...register("available", {required: "Veuillez renseigner ce champs pour continuer"})}>
                                {formData.disponibilityList.map((item) => {
                                    return (<option value={item.id} key={item.name + "_" + item.id}>{item.name}</option>)
                                })}
                            </select>
                        </div>
                    </div>

                    <SkillsBlock user={user} parentFormData={getValues()}/>
                    <SpecializationBlock user={user}/>
                    <ActivityBlock user={user}/>
                    <JobBlock user={user}/>
                    <FormationBlock user={user}/>
                    <AssociativeActivityBlock user={user}/>

                    <div id={"resources_details"} className={"profil_block"}>
                        <div className={"bloc_title_info"}>
                            <h2>Ressources</h2>
                        </div>
                        <div className={"form_row_wrapper type3"}>
                            <label>Site internet</label>
                            <input type={"text"} name={"website"} id={"website"} placeholder={"Url de votre site web"} {...register("website")}/>
                        </div>
                        <div className={"form_row_wrapper type3"}>
                            <label>Chaîne vidéo</label>
                            <input type={"text"} name={"videoChannel"} id={"videoChannel"} placeholder={"Url de votre chaine vidéo"} {...register("videoChannel")}/>
                        </div>
                    </div>

                </>}

                {user.job === 0 && <div className={"form_row_wrapper"}>
                    <div className={"checkbox_wrapper checkbox_blue"}>
                        <input type={"checkbox"} name={"communityPolicy"} id={"communityPolicy"} {...register("communityPolicy")}/>
                        <label htmlFor={"communityPolicy"}>Oui, je rejoins la communauté EsterLawyers, j'accepte la <Link href={"#0"} onClick={() => setShowCommunityPolicy(true)}>charte</Link></label>
                    </div>
                </div>}

                <div className={"text-right"}>
                    <button className={"cta full blue"}>Enregistrer le profil</button>
                </div>
            </form>

            <SlidingPanel show={company && company.id === -1} handleClose={() => setCompany(null)}>
                <CreateCompany handleClose={(company) => createNewCompany(company)} registrationNumber={companyInputValue} company={company}/>
            </SlidingPanel>
            <SlidingPanel show={company && company.id > 0} handleClose={() => setCompany(null)}>
                <JoinCompany user={user} handleClose={() => joinCompany(company)} company={company}/>
            </SlidingPanel>

            <SlidingPanel show={showProfileUpdated} handleClose={() => {
                setShowProfileUpdated(false)
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }}>
                <div>
                    <div className={"bloc_title_info"}>
                        <h2>Super !</h2>
                        <p>Profil mis à jour.</p>
                    </div>
                    <div className={"text-center mb2 mt3"}>
                        <button className={"cta full blue"} onClick={() => {
                            setShowProfileUpdated(false)
                            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                        }}>Fermer</button>
                    </div>
                </div>
            </SlidingPanel>
            <SlidingPanel show={errorSkill} handleClose={() => {
                setErrorSkill(false)
                const element = document.getElementById('skills_details');
                element?.scrollIntoView({
                    behavior: 'smooth'
                });
            }}>
                <div>
                    <div className={"bloc_title_info"}>
                        <h2>Compétence requise</h2>
                        <p>Vous devez ajouter au moins une compétence.</p>
                    </div>
                    <div className={"text-center mb2 mt3"}>
                        <button className={"cta full blue"} onClick={() => {
                            setErrorSkill(false)
                            const element = document.getElementById('skills_details');
                            element?.scrollIntoView({
                                behavior: 'smooth'
                            });
                        }}>Fermer</button>
                    </div>
                </div>
            </SlidingPanel>

            <SlidingPanel show={showCommunityPolicy} forceSliding={true} handleClose={() => setShowCommunityPolicy(false)}>
                <CommunityRoute readOnly={true} handleClose={() => setShowCommunityPolicy(false)} />
            </SlidingPanel>
            {/*<SlidingPanel show={showFile} forceSliding={true} handleClose={() => setShowFile(false)}>*/}
            {/*    /!*{doc && <FileViewer filePath={doc.uri} fileType={doc.fileType} />}*!/*/}
            {/*    {docs.length &&*/}
            {/*        // <Document file={docs[0].uri}*/}
            {/*        //           options={{*/}
            {/*        //               httpHeaders: {*/}
            {/*        //                   "Access-Control-Allow-Origin": "*",*/}
            {/*        //                   "Cache-Control": "no-cache",*/}
            {/*        //               },*/}
            {/*        //           }}>*/}
            {/*        //     /!*<Page pageNumber={pageNumber} />*!/*/}
            {/*        // </Document>*/}
            {/*        // <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} config={{*/}
            {/*        // header: {*/}
            {/*        //     disableHeader: false,*/}
            {/*        //     disableFileName: false,*/}
            {/*        //     retainURLParams: false,*/}
            {/*        // }*/}
            {/*    // }} />*/}
            {/*    }*/}
            {/*</SlidingPanel>*/}
        </div>
    )
}

const SkillsBlock = (props) => {

    //show / hide add form
    const [showAddForm, setShowAddForm] = useState(false)

    //if in edit mode set skill object to edit
    const [editItem, setEditItem] = useState(null)

    //Api
    const api = new Api();
    const revalidator = useRevalidator();

    //handle cancel form
    const handleCancel = () => {
        setEditItem(null)
        setShowAddForm(false)
    }

    //handle edit action
    const handleEdit = (item) => {
        setEditItem(item)
        setShowAddForm(true)
    }

    //handle delete action
    const deleteSkill = async (skill) => {
        props.user.skills = props.user.skills.filter((obj) => obj.id !== skill.id)
        revalidator.revalidate()
        await api.deleteUserSkill(skill.id)
    }

    //submit form
    const handleFormSubmit = async (data) => {

        if (editItem === null) {
            api.createUserSkill(data.description, data.area.id, data.experience.id, data.countries, data.subareas).then((data) => {
                props.user.skills.push(data)
                revalidator.revalidate()
                handleCancel()
            }).catch( error => {
                //TODO display error
            })
        } else {
            api.updateUserSkill(data.description, data.area.id, data.experience.id, data.countries, data.subareas, editItem.id).then((editedData) => {
                props.user.skills = props.user.skills.map(obj => {
                    if (obj.id === editedData.id) {
                        return editedData
                    }
                    return obj
                })
                revalidator.revalidate()
                handleCancel()
            }).catch( error => {
                //TODO display error
            })
        }
    }

    return(
        <div id={"skills_details"} className={"profil_block"}>
            <div className={"bloc_title_info"}>
                <h2>Compétences</h2>
                <p>Indiquez vos 3 principaux domaines d'expertise par ordre de préférence et précisez les sous-domaines.</p>
            </div>

            <div className={"skills_wrapper"}>

                {props.user.skills.map((item, i) => {
                    return(
                        <ProfilDataBlock key={`skill_data${i}`} title={item.area.name} experience={item.experience.name} international={item.countries} description={item.description} subareas={item.subareas} editCallBack={() => handleEdit(item)} deleteCallBack={() => deleteSkill(item)}/>
                    )
                })}

                {!showAddForm &&
                    <div className={"text-right"}>
                        <button className={"cta full blue reverse"} onClick={()=> setShowAddForm(true)}>+ Ajouter une compétence</button>
                    </div>
                }
            </div>

            {showAddForm &&
                <div id={"addskill_wrapper"}>
                    <div id={"addskill_form"} className={"profil_block"}>
                        <FormSkill user={props.user} onFormSubmit={handleFormSubmit} onFormCancel={handleCancel} skill={editItem} description={true} parentFormData={props.parentFormData}/>
                    </div>
                </div>
            }

        </div>
    )
}

const SpecializationBlock = (props) => {

    //show / hide add form
    const [showAddForm, setShowAddForm] = useState(false)

    //if in edit mode set skill object to edit
    const [editItem, setEditItem] = useState(null)

    //Api
    const api = new Api();
    const revalidator = useRevalidator();

    //handle cancel form
    const handleCancel = () => {
        setEditItem(null)
        setShowAddForm(false)
    }

    //handle edit action
    const handleEdit = (item) => {
        setEditItem(item)
        setShowAddForm(true)
    }

    //handle delete action
    const deleteSpecialization = async (specialization) => {
        props.user.specializations = props.user.specializations.filter((obj) => obj.id !== specialization.id)
        revalidator.revalidate()
        await api.deleteUserSpesialization(specialization.id)
    }

    //submit form
    const handleFormSubmit = async (data) => {
        if (editItem === null) {
            api.createUserSpesialization(data.description, data.specialization.id, data.experience.id).then((data) => {
                props.user.specializations.push(data)
                revalidator.revalidate()
                handleCancel()
            }).catch( error => {
                //TODO display error
            })
        } else {
            api.updateUserSpesialization(data.description, data.specialization.id, data.experience.id, editItem.id).then((editedData) => {
                props.user.specializations = props.user.specializations.map(obj => {
                    if (obj.id === editedData.id) {
                        return editedData
                    }
                    return obj
                })
                revalidator.revalidate()
                handleCancel()
            }).catch( error => {
                //TODO display error
            })
        }
    }

    return(
        <div id={"specialization_details"} className={"profil_block"}>

                <div className={"bloc_title_info"}>
                    <h2>Spécialisations</h2>
                    <p>Listez vos certifications de spécialisation.</p>
                </div>

                <div className={"skills_wrapper"}>

                    {props.user.specializations.map((item, i) => {
                        return(
                            <ProfilDataBlock key={`specialization_data${i}`} title={item.specialization.name} experience={item.experience.name}  description={item.description} editCallBack={() => handleEdit(item)} deleteCallBack={() => deleteSpecialization(item)}/>
                        )
                    })}

                    {!showAddForm &&
                        <div className={"text-right"}>
                            <button className={"cta full blue reverse"} onClick={() => setShowAddForm(true)}>+ Ajouter une spécialisation</button>
                        </div>
                    }

                </div>

                {showAddForm &&
                    <div id={"addspecialization_wrapper"}>
                        {/*<div className={"bloc_title_info"}>
                        <h2>Présentation de la section</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. (5 compétences par ordre de préférence et sous-domaines)</p>
                    </div>*/}
                        <div id={"addspecialization_form"} className={"profil_block"}>
                            <FormSpecialization description={true} onFormSubmit={handleFormSubmit} onFormCancel={handleCancel} specialization={editItem}/>
                        </div>
                    </div>
                }

            </div>
    );
}

const ActivityBlock = (props) => {

    //show / hide add form
    const [showAddForm, setShowAddForm] = useState(false)

    //if in edit mode set skill object to edit
    const [editItem, setEditItem] = useState(null)

    //Api
    const api = new Api();
    const revalidator = useRevalidator();

    //handle cancel form
    const handleCancel = () => {
        setEditItem(null)
        setShowAddForm(false)
    }

    //handle edit action
    const handleEdit = (item) => {
        setEditItem(item)
        setShowAddForm(true)
    }

    //handle delete action
    const deleteActivity = async (activity) => {
        props.user.industries = props.user.industries.filter((obj) => obj.id !== activity.id)
        await api.deleteUserIndustry(activity.id)
        revalidator.revalidate()
    }

    //submit form
    const handleFormSubmit = async (data) => {
        if (editItem === null) {
            api.createUserIndustry(data.description, data.industry.id, data.experience.id, data.countries).then((data) => {
                props.user.industries.push(data)
                revalidator.revalidate()
                handleCancel()
            }).catch( error => {
                //TODO display error
            })
        } else {
            api.updateUserIndustry(data.description, data.industry.id, data.experience.id, data.countries, editItem.id).then((editedData) => {
                props.user.industries = props.user.industries.map(obj => {
                    if (obj.id === editedData.id) {
                        return editedData
                    }
                    return obj
                })
                revalidator.revalidate()
                handleCancel()
            }).catch( error => {
                //TODO display error
            })
        }
    }

    return(
        <div id={"activity_details"} className={"profil_block"}>

            <div className={"bloc_title_info"}>
                <h2>Secteurs d’activité</h2>
                <p>Précisez vos secteurs d'activité de prédilection.</p>
            </div>

            <div className={"skills_wrapper"}>

                {props.user.industries.map((item, i) => {
                    return(<ProfilDataBlock key={`secteur_data${i}`} title={item.industry.name} experience={item.experience ? item.experience.name : null} international={item.countries} description={item.description} editCallBack={() => handleEdit(item)} deleteCallBack={() => deleteActivity(item)}/>)
                })}

                {!showAddForm &&
                    <div className={"text-right"}>
                        <button className={"cta full blue reverse"} onClick={() => setShowAddForm(true)}>+ Ajouter un secteur d'activité</button>
                    </div>
                }

            </div>

            {showAddForm &&
                <div id={"addactivity_wrapper"}>
                    <div id={"addactivity_form"} className={"profil_block"}>
                        <FormActivity user={props.user} description={true} onFormSubmit={handleFormSubmit} onFormCancel={handleCancel} activity={editItem}/>
                    </div>
                </div>
            }

        </div>
    );
}

const JobBlock = (props) => {

    //show / hide add form
    const [showAddForm, setShowAddForm] = useState(false)

    //if in edit mode set skill object to edit
    const [editItem, setEditItem] = useState(null)

    //Api
    const api = new Api();
    const revalidator = useRevalidator();

    //handle cancel form
    const handleCancel = () => {
        setEditItem(null)
        setShowAddForm(false)
    }

    //handle edit action
    const handleEdit = (item) => {
        setEditItem(item)
        setShowAddForm(true)
    }

    //handle delete action
    const deleteJob = async (job) => {
        props.user.jobs = props.user.jobs.filter((obj) => obj.id !== job.id)
        await api.deleteUserJob(job.id)
        revalidator.revalidate()
    }

    //submit form
    const handleFormSubmit = async (data) => {
        if (editItem === null) {
            api.createUserJob(data.title, data.description).then((data) => {
                props.user.jobs.push(data)
                revalidator.revalidate()
                handleCancel()
            }).catch( error => {
                //TODO display error
            })
        } else {
            api.updateUserJob(data.title, data.description, editItem.id).then((editedData) => {
                props.user.jobs = props.user.jobs.map(obj => {
                    if (obj.id === editedData.id) {
                        return editedData
                    }
                    return obj
                })
                revalidator.revalidate()
                handleCancel()
            }).catch( error => {
                //TODO display error
            })
        }
    }

    return(
        <div id={"job_details"} className={"profil_block"}>

            <div className={"bloc_title_info"}>
                <h2>Parcours professionnel</h2>
                <p>Présentez votre parcours en précisant dates, postes et détails des postes / collaborations.</p>
            </div>

            <div className={"skills_wrapper"}>

                {props.user.jobs.map((item, i) => {
                    return(<ProfilDataBlock key={`job_data${i}`} title={item.title} description={item.description} editCallBack={() => handleEdit(item)} deleteCallBack={() => deleteJob(item)}/>)
                })}

                {!showAddForm &&
                    <div className={"text-right"}>
                        <button className={"cta full blue reverse"} onClick={() => setShowAddForm(true)}>+ Ajouter un parcours</button>
                    </div>
                }

            </div>

            {showAddForm &&
                <div id={"addactivity_wrapper"}>
                    <div id={"addjob_form"} className={"profil_block"}>
                        <FormJob user={props.user} onFormSubmit={handleFormSubmit} onFormCancel={handleCancel} job={editItem} />
                    </div>

                </div>
            }

        </div>
    );
}

const FormationBlock = (props) => {

    //show / hide add form
    const [showAddForm, setShowAddForm] = useState(false)

    //if in edit mode set skill object to edit
    const [editItem, setEditItem] = useState(null)

    //Api
    const api = new Api();
    const revalidator = useRevalidator();

    //handle cancel form
    const handleCancel = () => {
        setEditItem(null)
        setShowAddForm(false)
    }

    //handle edit action
    const handleEdit = (item) => {
        setEditItem(item)
        setShowAddForm(true)
    }

    //handle delete action
    const deleteEducation = async (education) => {
        props.user.educations = props.user.educations.filter((obj) => obj.id !== education.id)
        await api.deleteUserEducation(education.id)
        revalidator.revalidate()
    }

    //submit form
    const handleFormSubmit = async (data) => {
        if (editItem === null) {
            api.createUserEducation(data.title, data.description).then((data) => {
                props.user.educations.push(data)
                revalidator.revalidate()
                handleCancel()
            }).catch( error => {
                //TODO display error
            })
        } else {
            api.updateUserEducation(data.title, data.description, editItem.id).then((editedData) => {
                props.user.educations = props.user.educations.map(obj => {
                    if (obj.id === editedData.id) {
                        return editedData
                    }
                    return obj
                })
                revalidator.revalidate()
                handleCancel()
            }).catch( error => {
                //TODO display error
            })
        }
    }

    return(
        <div id={"job_details"} className={"profil_block"}>

            <div className={"bloc_title_info"}>
                <h2>Formations et certifications</h2>
                <p>Indiquez vos diplômes et certifications.</p>
            </div>

            <div className={"skills_wrapper"}>

                {props.user.educations.map((item, i) => {
                    return(<ProfilDataBlock key={`education_data${i}`} title={item.title} description={item.description} editCallBack={() => handleEdit(item)} deleteCallBack={() => deleteEducation(item)}/>)
                })}

                {!showAddForm &&
                    <div className={"text-right"}>
                        <button className={"cta full blue reverse"} onClick={() => setShowAddForm(true)}>+ Ajouter une formation</button>
                    </div>
                }

            </div>

            {showAddForm &&
                <div id={"addactivity_wrapper"}>

                    <div id={"addactivity_form"} className={"profil_block"}>
                        <FormEducation user={props.user} onFormSubmit={handleFormSubmit} onFormCancel={handleCancel} education={editItem}/>
                    </div>

                </div>
            }

        </div>
    );
}

const AssociativeActivityBlock = (props) => {

    //show / hide add form
    const [showAddForm, setShowAddForm] = useState(false)

    //if in edit mode set skill object to edit
    const [editItem, setEditItem] = useState(null)

    //Api
    const api = new Api();
    const revalidator = useRevalidator();

    //handle cancel form
    const handleCancel = () => {
        setEditItem(null)
        setShowAddForm(false)
    }

    //handle edit action
    const handleEdit = (item) => {
        setEditItem(item)
        setShowAddForm(true)
    }

    //handle delete action
    const deleteAssociation = async (association) => {
        props.user.associations = props.user.associations.filter((obj) => obj.id !== association.id)
        await api.deleteUserAssociation(association.id)
        revalidator.revalidate()
    }

    //submit form
    const handleFormSubmit = async (data) => {
        if (editItem === null) {
            api.createUserAssociation(data.title, data.description).then((data) => {
                props.user.associations.push(data)
                revalidator.revalidate()
                handleCancel()
            }).catch( error => {
                //TODO display error
            })
        } else {
            api.updateUserAssociation(data.title, data.description, editItem.id).then((editedData) => {
                props.user.associations = props.user.associations.map(obj => {
                    if (obj.id === editedData.id) {
                        return editedData
                    }
                    return obj
                })
                revalidator.revalidate()
                handleCancel()
            }).catch( error => {
                //TODO display error
            })
        }
    }

    return(
        <div id={"ressource_details"} className={"profil_block"}>

            <div className={"bloc_title_info"}>
                <h2>Activités associative</h2>
                <p>Présentez vos activités associatives</p>
            </div>

            <div className={"skills_wrapper"}>

                {props.user.associations.map((item, i) => {
                    return(<ProfilDataBlock key={`education_data${i}`} title={item.title} description={item.description} editCallBack={() => handleEdit(item)} deleteCallBack={() => deleteAssociation(item)}/>)
                })}

                {!showAddForm &&
                    <div className={"text-right"}>
                        <button className={"cta full blue reverse"} onClick={() => setShowAddForm(true)}>+ Ajouter une activité</button>
                    </div>
                }

            </div>

            {showAddForm &&
                <div id={"addactivity_wrapper"}>

                    <div id={"addactivity_form"} className={"profil_block"}>
                        <FormAssociation user={props.user} onFormSubmit={handleFormSubmit} onFormCancel={handleCancel} association={editItem}/>
                    </div>

                </div>
            }

        </div>
    );
}

export default ProfileMission;