import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import ItemLabel from "../../other/ItemLabel";
import {useSetState} from "react-use";
import switchs from "../switch/Switchs";
import {STEP_AGREEMENT, STEP_DONE, STEP_PENDING, STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED} from "../../other/Constant";

const EmployeeBlock = (props) => {

    const navigate = useNavigate();
    const [state, setState] = useSetState({
        completion : "",
        status : "..."
    })

    useEffect(() => {
        // console.log(props)
    },[])

    return(
        <div className={`sponsorship_item`}>
            <div className={"content"}>
                <div className={"name"}>{props.firstname} {props.lastname}</div>
                {/*<div className={"detail"}>*/}
                    <div className={"email"}>{props.email}</div>
                    <div className={"phone"}>{props.phone}</div>
                    {!props.used && <div className={"status"}>En attente</div>}
                    {props.used && <div className={"status"}>Filleul inscrit</div>}
                {/*</div>*/}
                <div className={"progression"}>
                    {/*{state.completion}*/}
                </div>
                {/*<div className={"detail d-mobile-none"}>{props.description}</div>*/}
            </div>
        </div>
    )
}

EmployeeBlock.defaultProps = {
    firstname : "Romain",
    lastname : "Vitry",
    email : "romain.vitry@esterlaw.io",
    phone : "0102030405",
    // showNotification : true
}

export default EmployeeBlock