import React, {useContext, useEffect, useState} from "react";

const Footer = (props) => {

    useEffect(() => {
        (function(d, w, c) {
            w.BrevoConversationsID = '65424e92428847140e4bb250';
            w[c] = w[c] || function() {
                (w[c].q = w[c].q || []).push(arguments);
            };
            var s = d.createElement('script');
            s.async = true;
            s.src = 'https://conversations-widget.brevo.com/brevo-conversations.js';
            console.log("TEST")
            if (d.head) d.head.appendChild(s);
        })(document, window, 'BrevoConversations');
    }, []); // Ce useEffect s'exécute une seule fois lors du montage

    return(
        <footer>

        </footer>
    )
}

Footer.defaultProps = {
    user: {}
}

export default Footer