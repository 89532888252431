import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useChat} from "./Twilio";
import * as Constant from "../other/Constant";
import {getLinkingStep, getLinkingTime} from "../helper/other";
import userCircle from "../../images/mobile/user-circle.png";

/**
 * Empty item
 * @param title
 * @returns {JSX.Element}
 * @constructor
 */
export const GenericEmptyItem = ({title}) => {

    return(
        <div className={"generic_data_block empty"}>
            {title}
        </div>
    )
}

/**
 * Block to display pending linking count
 * @param count
 * @param link
 * @returns {JSX.Element}
 * @constructor
 */
export const SingleMatchBlock = ({count, link, user, type}) => {

    const navigate = useNavigate()

    return(
        <div className={"match_block pending_matchs"} onClick={() => navigate(link)}>
            <div className={`picture ${user?.profileType === Constant.PROFIL_TYPE_CANDIDAT ? 'talent' : ''}`}/>
            <div className={"content vcentered"}>
                <div className={"title"}>{count} match{parseInt(count) > 1 && "s"} en attente</div>
                {!type && <div className={"description"}>{user?.profileType === Constant.PROFIL_TYPE_CANDIDAT ? 'Trouvez votre mission idéale' : 'Trouvez votre collaborateur idéal'}</div>}
            </div>
        </div>
    )
}

export const SingleProfilTalentBlock = ({linking, profil, type}) => {

    const navigate = useNavigate()

    let link = `/matchs/mission/${linking.id}`
    if (type === 'help') {
        link = `/matchs/aide/${linking.id}`
    } else if (type === 'switch') {
        link = `/matchs/echange/${linking.id}`
    }

    return (
        <div className={"profil_talent_block"} onClick={() => navigate(link)}>
            <div className={"picture"}>
                <img src={profil?.profilePicture ? profil?.profilePicture : userCircle} />
            </div>
            <div className={"content vcentered"}>
                <div className={"title"}>{profil?.firstname} {profil?.lastname}</div>
                <p>{getLinkingStep(linking)} - {getLinkingTime(linking)}</p>
            </div>
            {/*<div className={"square"}></div>*/}
            <div className={""}> > </div>
        </div>
    )
}

export const SingleItemMessageBlock = ({uid}) => {

    const [currentConversation, setCurrentConversation] = useState(null)

    //get last conversation information
    const {conversations} = useChat()

    useEffect(() => {
        console.log(conversations)
        setCurrentConversation(conversations.filter((conv) => conv.sid === uid))
    }, [conversations]);

    useEffect(() => {
        console.log(currentConversation)
    }, [currentConversation]);

    return(
        <div className={"single_item_message_block"}>

        </div>
    )
}