import React, {useEffect, useState} from 'react';
import {useChat} from "../../other/Twilio";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    Avatar,
    MessageSeparator,
    ConversationHeader,
    StarButton,
    VoiceCallButton,
    VideoCallButton,
    InfoButton,
    Button
} from '@chatscope/chat-ui-kit-react';
import Api from "../../helper/api";
import {CtaReturn} from "../../other/Cta";
import {set} from "react-hook-form";
import moment from "moment/moment";
import 'moment/locale/fr';
import {FaVideo} from "react-icons/fa";
import userCircle from '../../../images/mobile/user-circle.png'

const ConversationSingle = (props) => {

    const [currentConversation, setCurrentConversation] = useState(null)
    const [messages, setMessages] = useState([])
    const [interlocutor, setInterlocutor] = useState('')
    const { conversationId, type } = useParams()
    const {conversations, chatClient, sendMessage} = useChat()
    const [online, setOnline] = useState(false)
    const {user} = useOutletContext();
    const [linking, setLinking] = useState(null)
    const navigate = useNavigate()

    const api = new Api();

    const fetchMessages = async (paginator, lastMessageIndex) => {
        // let allMessages = [];
        // let hasMoreMessages = true;
        // let paginator = null;
        // if (lastMessageIndex > 0) {
        //     paginator = await conv.getMessages(30, lastMessageIndex);
        // } else {
        //     paginator = await conv.getMessages(30);
        // }
        // if (paginator.items.length) {
        //     const formatedMessages = paginator.items.map((message) => formatMessage(message))
        //     setMessages([...messages, ...formatedMessages])
        //
        //     console.log(currentConversation.sid, 'currentConversation')
        //     console.log(paginator.hasNextPage, '3333')
        //     if (paginator.hasNextPage) {
        //         const nextPaginator = paginator.nextPage()
        //         console.log(paginator.items[paginator.items.length - 1]?.index, '4444')
        //         fetchMessages(paginator, paginator.items[paginator.items.length - 1]?.index)
        //         // fetchMessages(conv, paginator.items[paginator.items.length - 1]?.index)
        //     }
        // }
        //     // Ajouter les messages récupérés à notre tableau
        //
        //     // Vérifier s'il y a d'autres messages à récupérer
        //     hasMoreMessages = page.hasNextPage;
        //     lastMessageIndex = page.items[page.items.length - 1]?.index;
        // setMessages(allMessages);
        // setIsLoading(false);
    };
    const setup = () => {
        //set all message on read
        // currentConversation.setAllMessagesRead()
        let linkingType = type
        if (props.type) {
            linkingType = props.type
        }
        console.log(linkingType, "LINKING TYPE")
        api.getLinkingByConversation(currentConversation.sid, linkingType).then(async (data) => {
            console.log(data, "CONV DATA")
            if (data && data.length) {
                setLinking(data[0])
                setInterlocutor(`${data[0].user.firstname}`)
                // setInterlocutor(`${data[0].user.firstname} ${data[0].user.lastname}`)
                // const paginator = await currentConversation.getMessages();
                // fetchMessages(paginator, 0)
            }
        })
    }

    useEffect(() => {
        if (currentConversation) {
            setup()
        }
    }, [currentConversation]);


    //load the first conversations
    const initConversation = () => {
        if (props.conv) { //DESKTOP
            console.log("FROM PROP CONV")
            const messages = props.conv.messages.map((message) => formatMessage(message))
            setMessages(messages)
            setCurrentConversation(props.conv)
        } else { //MOBILE OR RELOAD
            conversations.forEach((conversation, key) => {
                console.log(conversationId, "FROM CONVERSATIONS ID")
                console.log(conversation.sid, "FROM CONVERSATIONS")
                if (conversation.sid === conversationId) {
                    //format message to be displayed
                    const messages = conversation.messages.map((message) => formatMessage(message))
                    setMessages(messages)
                    setCurrentConversation(conversation)

                    return true;
                }
            })
        }
    }
    useEffect(initConversation, [conversations, props.conv]);

    //receive new message
    const handleNewMessage = (message) => {
        if (message.channel.sid === conversationId || message.channel.sid === props.conv?.sid) {
            // Ajouter le nouveau message à la liste des messages actuels
            setMessages((prevMessages) => [...prevMessages, formatMessage(message)]);
            scrollConversationToBottom()
        }
    };

    //format message to be displayed in the chat component
    const formatMessage = (message) => {
        const hours = message.state.timestamp.getHours();
        const minutes = message.state.timestamp.getMinutes();

        const day = message.state.timestamp.getDate();
        const month = message.state.timestamp.getMonth() + 1;
        const year = message.state.timestamp.getFullYear();

        return({
            _id: message.sid,
            message: message.state.body,
            // sentTime: message.dateCreated,
            sender : message.author,
            position : "single",
            direction : chatClient.user.identity === message.author ? "outgoing" : "incoming",
            senttime : message.dateCreated ? moment(message.dateCreated).locale('fr').format('H:m') : '',
            sentdate : `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`,
            name : message.author.charAt(0)+message.author.charAt(1)
        })
    }

    //send message
    const handleOnSend = (html, textContent, innerText, nodeList) => {
        //console.log(html,textContent, innerText, nodeList)
        sendMessage(currentConversation.sid, innerText)
    }

    //handle visio chat starting
    const handleVideoChat = async () => {
        //create link and send it
        let linkingType = 'linkings'
        if (linking.help) {
            linkingType = 'help_linkings'
        } else if (linking.switchDuty) {
            linkingType = 'switch_duty_linkings'
        }
        sendMessage(currentConversation.sid, `${user.firstname} invite ${interlocutor} : <a href='/messagerie/visio/${currentConversation.sid}/${linkingType}'>Rejoindre la visio</a>`)
        navigate(`/messagerie/visio/${currentConversation.sid}/${linkingType}`)
    }

    const scrollConversationToBottom = () => {
        if (document.getElementById("messageList")) {
            document.getElementById("messageList").scrollTop = document.getElementById("messageList").scrollHeight;
        }
    }

    //bind message event
    useEffect(() => {

        if (chatClient === null) {
            return;
        }

        // Abonner à l'événement de réception de message
        chatClient.on('messageAdded', handleNewMessage);

        if (chatClient.reachabilityEnabled && currentConversation) {
            currentConversation.getMembers().then(participants => {
                participants.forEach(async (participant) => {
                    const u = await participant.getUser();
                    if (u.identity != user?.uid && u.online) {
                        console.log(u.identity, 'IDENTITY')
                        console.log(user.uid, 'UID')
                        setOnline(true)
                    }
                });
            })
            chatClient.on('userUpdated', (data) => {
                if (data && data.user.identity != user?.uid) {
                    console.log(data.updateReasons, 'CHECK IS ONLINE ?')
                    console.log(data.user.identity, 'IDENTITY')
                    console.log(user.uid, 'UID')
                    // if (data.updateReasons.indexOf('notifiable') !== -1) {
                    if (data.updateReasons.indexOf('online') !== -1) {
                        setOnline(true);
                    } else {
                        setOnline(false);
                    }
                }
            });
        }

        // Nettoyer les abonnements à l'événement lors du démontage du composant
        return () => { chatClient.off('messageAdded', handleNewMessage); };

    }, [chatClient, currentConversation]);

    //scroll down on new message
    useEffect(() => {
        scrollConversationToBottom()
    }, [messages]);

    return (
        <div id={"page_conversation_single"}>
            <div className="cta_wrapper">
                <CtaReturn />
            </div>
            {messages !== null &&
                <MainContainer>
                    <ChatContainer>
                        <ConversationHeader>
                            <ConversationHeader.Back />
                            <Avatar
                                name={interlocutor}
                                src={linking?.user?.profilePicture ? linking.user.profilePicture : userCircle}
                                status={!linking?.userDeclinedOn && !linking?.ownerDeclinedOn && online ? 'available' : 'invisible'}
                            />
                            <ConversationHeader.Content
                                info={!linking?.userDeclinedOn && !linking?.ownerDeclinedOn && online ? 'En ligne' : 'Hors ligne'}
                                // userName={currentConversation?.friendlyName ? currentConversation?.friendlyName : interlocutor}
                                userName={interlocutor}
                            />
                            {!linking?.userDeclinedOn && !linking?.ownerDeclinedOn && <ConversationHeader.Actions>
                                <div className={'cta full blue'} onClick={handleVideoChat}><Button title="Visio" onClick={handleVideoChat} icon={<FaVideo color={'#FFFFFF'}/>} /> Inviter à une nouvelle visio</div>
                            </ConversationHeader.Actions>}
                        </ConversationHeader>

                        <MessageList id={"messageList"}>
                            {messages.map((message, key) => {
                                return(
                                    <>
                                        {/*Add date as separator if different from previous one*/}
                                        { (key === 0 || messages.at(key-1).sentdate !== message.sentdate ) &&
                                            <MessageSeparator>{message.sentdate}</MessageSeparator>
                                        }
                                        {/*<Message model={message} key={`message_${key}`} avatarSpacer={messages.at(key-1).sender === message.sender && message.direction === "incoming"}>*/}
                                        <Message model={message} key={`message_${key}`}>
                                            {/*display avatar only for first user message and only for incoming message*/}
                                            {/*{messages.at(key-1).sender !== message.sender && message.direction === "incoming" &&*/}
                                            {/*    <Avatar name={message.name} size={"md"}/>*/}
                                            {/*}*/}
                                            <Message.HtmlContent html={message.message}/>
                                            {/*<Message.Footer />*/}
                                            <Message.Footer>
                                                {message.senttime}
                                                {/*{message.dateCreated}*/}
                                            </Message.Footer>
                                        </Message>
                                    </>)
                                })
                            }
                        </MessageList>
                        {!linking?.userDeclinedOn && !linking?.ownerDeclinedOn && <MessageInput placeholder="Écrivez votre message ici" onSend={handleOnSend} />}
                    </ChatContainer>
                </MainContainer>
            }
        </div>
    )

}

export default ConversationSingle