import React, {useEffect, useState, Suspense, useContext} from "react";
import {Await, useLoaderData, useLocation, useNavigate, useOutletContext} from "react-router-dom";

import {GenericEmptyItem} from "../../other/GenericBlock";
import {CtaReturn} from "../../other/Cta";
import SingleMatch from "../match/SingleMatch";
import Api from "../../helper/api";
import {isMobile} from 'react-device-detect';
import {
    PROFIL_TYPE_RECRUTEUR,
    STEP_AGREEMENT, STEP_DONE,
    STEP_PENDING,
    STEP_RECIPIENT_ACCEPTED,
    STEP_RECIPIENT_REFUSED,
    STEP_SENDER_ACCEPTED, STEP_SENDER_REFUSED
} from "../../other/Constant";
import moment from "moment/moment";
import {getLinkingStep, getLinkingTime} from "../../helper/other";
import userCircle from "../../../images/mobile/user-circle.png";
import {AuthContext} from "../../userAuth/AuthContext";

const MatchBlock = ({title, match, isLiked, onClick}) => {

    const navigate = useNavigate()

    return(
        <div className={`profil_talent_block ${isLiked?"liked":""}`} onClick={() => onClick()}>
            <div className={"picture"}>
                <img src={match?.user?.profilePicture ? match.user?.profilePicture : userCircle} />
            </div>
            {/*<div className={`match_item list ${isLiked?"liked":""}`} onClick={() => navigate("/matchs/"+type+"/"+itemId)}>*/}
            <div className={"content"}>
                <div className={"title"}>{title}</div>
                <div className={"tags"}>
                    <p>{getLinkingStep(match)} - {getLinkingTime(match)}</p>
                    {/*<span>{compatibility}% compatible</span>*/}
                </div>
            </div>
        </div>
    )
}

const SingleItemMatchsListing = () => {
    const location = useLocation();
    // console.log(location, 'CHECK PARAMS')

    const {matchs, type} = useLoaderData()
    const [matchsList, setMatchsList] = useState([]);
    const navigate = useNavigate()
    const [match, setMatch] = useState(null)
    const api = new Api()
    const {user} = useOutletContext();

    useEffect(() => {
        if (type == "mission" && user.mission === true && user.profileType != PROFIL_TYPE_RECRUTEUR) {
            api.UpdateUser(user.id, {
                profileType : PROFIL_TYPE_RECRUTEUR
            }).then((user) => {
                window.location.reload()
            })
        }
        matchs.then((matchs) => {
            let formatted_matchs = []
            matchs.forEach((match, key) => {
                let title = `Match ${key + 1}`
                if (match.user && match.user?.lastname && match.user?.firstname) {
                    title = `${match.user?.firstname} ${match.user?.lastname}`
                }

                formatted_matchs.push(<MatchBlock title={title} match={match} itemId={match.id} type={type} isLiked={match.bookmarks?.length > 0} onClick={() => {
                    if (isMobile) {
                        navigate("/matchs/"+type+"/"+match.id)
                    } else {
                        if (type === 'aide') {
                            setMatch(api.getHelpMatch(match.id))
                        } else if (type === 'echange') {
                            setMatch(api.getSwitchMatch(match.id))
                        } else {
                            setMatch(api.getMatch(match.id))
                        }
                    }
                }}/>)
            })
            if (!isMobile) {
                setMatch(matchs[0])
            }
            setMatchsList(formatted_matchs)
        })
    },[])

    return(
        <div className={"single_item_match_listing_wrapper"}>
            <div className={"cta_wrapper"}>
                <CtaReturn/>
            </div>
            <h2 className={"title"}>{location.state?.title}</h2>
            <div className={"generic_data_wrapper"}>

                <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                    <Await resolve={matchs}>
                        {matchsList.length > 0 && <>
                            <div className={"side-right"}>
                                {matchsList}
                            </div>
                            {!isMobile && <div className={"side-left border-side-left"}>
                                {match !== null && <SingleMatch match={match} type={type} multiple={true} />}
                            </div>}
                        </>}
                        {matchsList.length === 0 && <GenericEmptyItem title={"Aucun match pour le moment"}/>}
                    </Await>
                </Suspense>
            </div>

        </div>
    )
}

export default SingleItemMatchsListing